import { getColor, getRankColor, seconds2HHmm, formatDateString } from '@/plugins/utils/utils';

import { locales } from '@/plugins/utils/locales';
const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

let sol_chart   = null;
let chart_data = null;

import {
    chart_dates, // 图表横轴的日期与报告数据之间的映射关系
    getInterval
  } from './variables.js';


function getSymbol(value, params, data) { //单点数据需要显示symbol，能连成线则不显示
    var left = params.dataIndex -1;
    var right = params.dataIndex +1;
    var symbol = 'none';
    if (left>=0 && right<data.length) { //中间单点
        if (data[left].value==null && data[right].value==null) {
            symbol = 'circle';
        }
    } else if (right<data.length) { //最左边缘
        if (data[right].value==null) symbol = 'circle';
    } else if (left>=0) { //最右边缘
        if (data[left].value==null) symbol = 'circle';
    }
    return symbol;
}

export function setSOLChartOption(reports,sol_chart,chart_dates) {
    chart_data = reports;
    if (!sol_chart) {
        return;
    }

    var dataSOL = [];
    var dataXAxis = [];

    //从日期序列转换到图表数据
    var weekmode = false;
    if (Object.keys(reports).length > 0) {
        var total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total<=60) {
            for (let key in chart_dates) {
                try {
                    const [year,month, day] = key.split('/');
                    const abbrKey = month + '-' + day;
                    
                    // 使用可选链操作符
                    const sol = Math.round(reports?.[key]?.sol); // 如果没有值则默认为0
                    let c = getRankColor(reports[chart_dates[key]], 'sol', sol);
                    
                    dataSOL.push({ 
                        name: abbrKey, 
                        value: sol, 
                        itemStyle: { color: c } 
                    });
                    dataXAxis.push(abbrKey);
                } catch (error) {
                    console.error(`Error processing date ${key}:`, error);
                    // 可以选择继续处理下一个数据点
                    continue;
                }
            }
            
        } else {
            weekmode = true;
            let [sol_avg, count] = [0, 0];
            let valid = false; // 跳过开头的"半周"
            let weekend = '';
            for (let key in chart_dates) {
                let [year, month, day] = key.split('/');
                let d = new Date();
                d.setHours(0, 0, 0, 0);
                d.setFullYear(year, month - 1, day);
                let weekday = d.getDay();

                let sol = Math.round(reports?.[key]?.sol ); // 如果没有值则默认为0
                if (sol!=null && valid) {
                    sol_avg += sol;
                    count++;
                }
                if (weekday===6) { //每周最后一天
                    weekend = month+'-'+day;
                }
                if (weekday===0) { //每周第一天
                    if (valid) {
                        sol_avg = count ? Math.round(sol_avg / count) : null;
                        key = month+'-'+day + L.to + weekend;
                        let c = getRankColor(null, 'sol', sol_avg);
                        dataSOL.push({ name: key, value: sol_avg, itemStyle: { color: c } });
                        dataXAxis.push( key );
                        [sol_avg, count] = [0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
        }
    }
    
    dataSOL.reverse();
    dataXAxis.reverse();
    var option = {
        animationDuration: 500,
        backgroundColor: 'transparent',
        /*title: {
            text: '入睡速度 (分钟)',
            padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        },*/
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesDesc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            axisPointer: {
                type: 'line',
                label: { 
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value) => { 
                if (value > 0 || value === 0) {
                    return seconds2HHmm(value * 60, true);
                } else {
                    return '-';
                }
            }
        },
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: { alignWithLabel: true },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : 1,
                formatter: (value)=>{
                    if (weekmode) {
                        let [weekstart, weekend] = value.split(L.to);
                        let [startMonth, startDay] = weekstart.split('-');
                        let [endMonth, endDay] = weekend.split('-');
                        if (startMonth!=endMonth || startDay==1) { // 月初
                            return monthNameArray[parseInt(endMonth) -1];
                        } else { return '' }
                    } else { return value; }
                }
            },
            data: dataXAxis,
        }],
        yAxis:{
            type: 'value',
            min: 0,
            minInterval: 10,
            axisLabel: {
                show: true,
                formatter: '{value}' + L.time_min,
            }
        },
        series: [ {
            name: L.sleep_onset_latency,
            type: 'line',
            smooth: true,
            //symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataSOL); },
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: { color: getColor('linegray1') },
            itemStyle: { color: getColor('linegray1') },
            data: dataSOL,
            tooltip: {
                show: true
            },
        }]
    };
    sol_chart.setOption(option);
}
