import { getColor, seconds2HHmm, formatDateString } from '@/plugins/utils/utils';

import { locales } from '@/plugins/utils/locales';
const L = locales['zh-Hans']

let env_chart = null;
let env_data  = null;  //环境数据


export function getSymbol(value, params, data) { //单点数据需要显示symbol，能连成线则不显示
    var left = params.dataIndex -1;
    var right = params.dataIndex +1;
    var symbol = 'none';
    if (left>=0 && right<data.length) { //中间单点        
        if (data[left][3]==null && data[right][3]==null) {
            symbol = 'circle';
        }
    } else if (right<data.length) { //最左边缘
        if (data[right][3]==null) symbol = 'circle';
    } else if (left>=0) { //最右边缘
        if (data[left][3]==null) symbol = 'circle';
    }
    return symbol;
}

export function setDayEnvChartOption(env_chart,env_data) {
    if (!env_chart || !env_data) {
        return;
    }

    var data = [];
    var dataMask = [];
    if (env_data && env_data.segments) {
        var offset = env_data.start;
        var end = env_data.end;
        var lastend = 0;
        if (env_data.segments[0].time>0) {
            data.push([offset, null, null, null]);
        }
        for (let i = 0; i<env_data.segments.length; i++) {
            //data.push([offset + env_data.segments[i].time, 0, 0, 0]);
            data.push([offset + env_data.segments[i].time, 
                env_data.segments[i].t<0 ? null : env_data.segments[i].t,
                env_data.segments[i].h<0 ? null : env_data.segments[i].h,
                env_data.segments[i].i<0 ? null : env_data.segments[i].i,
            ]);
            lastend = offset + env_data.segments[i].time;
        }
        if (lastend < end) {
            data.push([end, null, null, null]);
        }

        //扣掉wake分期的部分
        for (let i=0; i<env_data.stage?.length; i++) {
            let seg = env_data.stage[i];
            if (seg.value<2)
                dataMask.push ([ { name: '非睡眠', xAxis: env_data.start + seg.start }, { xAxis: env_data.start + seg.end } ]);
        }
        /*for (let i=0; i<env_data.nointention?.length; i++) {
            let seg = env_data.nointention[i];
            if (seg.e - seg.s > 60)
                dataMask.push ([ { name: '活动', xAxis: env_data.start + seg.s }, { xAxis: env_data.start + seg.e } ]);
        }
        for (let i=0; i<env_data.leavebed?.length; i++) {
            let seg = env_data.leavebed[i];
            if (seg.e - seg.s > 60)
                dataMask.push ([ { name: '离床', xAxis: env_data.start + seg.s }, { xAxis: env_data.start + seg.e } ]);
        }*/
    }

    var screenWidth = 0;
    try{
        let info = wx.getWindowInfo();
        screenWidth = info.screenWidth || 0;
    }catch(e) {}

    var colors = ['#F4912E', '#3C8DD0', '#3CB043'];
    var option = {
        backgroundColor: 'transparent',
        grid: [{
          left: 36,
          right: 36,
          top: '13%',
          height: '42%',
          //containLabel: true
        }, {
            left: 36,
            right: 36,
            height: '15%',
            bottom: '4%',
            //containLabel: true
        }],
        /*legend: {
            data: [L.illuminance, L.temperature, L.humidity],
            orient: 'horizontal',
            bottom: 1,
            selectedMode: false,
            icon: 'circle',
            itemHeight: screenWidth<=320 ? 10 : 12,
            itemWidth: 14,
            textStyle: {
                fontSize: screenWidth<=320 ? 10 : 12,
            }
        },*/
        axisPointer: { link: [{ xAxisIndex: 'all' }] },
        xAxis: [{
            min: 'dataMin',
            max: 'dataMax',
            minInterval: 120*60,
            maxInterval: 120*60,
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                formatter: function (val) {
                    var d = new Date(val*1000);
                    return d.getHours()+":"+d.getMinutes().toString().padStart(2, '0');
                }
            },
            splitLine: {
                lineStyle: { type: 'dashed' },
            },
        }, {
            gridIndex: 1,
            min: 'dataMin',
            max: 'dataMax',
            minInterval: 120*60,
            maxInterval: 120*60,
            //axisLine: { show: false, },
            axisLabel: { show: false, },
            axisTick: { show: false, },
            splitLine: {
                lineStyle: { type: 'dashed' },
            },
        }],
        yAxis: [{
            name: L.temperature_abbr,
            type: 'value',
            position: 'left',
            //alignTicks: true,
            nameGap: 6,
            axisLine: {
                show: true,
                lineStyle: { color: colors[0] }
            },
            axisLabel: { 
                formatter: (value, idx)=>{ 
                    let c = Math.round(value*1)/1;
                    if (value === c)
                        return c + '°C'; 
                    else
                        return null;
                }
            },
            //min: function (value) { return Math.floor((value.min-1)/3)*3 },
            //max: function (value) { return Math.ceil((value.max+1)/3)*3 },
            min: function (value) { return Math.round(value.min - 2) },
            max: function (value) { return Math.round(value.max + 2) },
            minInterval: 2,
        }, {
            name: L.humidity_abbr,
            type: 'value',
            position: 'right',
            //alignTicks: true,
            nameGap: 6,
            axisLine: {
                show: true,
                lineStyle: { color: colors[1] }
            },
            splitLine: { show: false, },
            axisLabel: { 
                formatter: (value, idx)=>{ 
                    let c = Math.round(value*1)/1;
                    if (value === c)
                        return c + '%'; 
                    else
                        return null;
                }
            },
            //min: function (value) { return value.min>1 ? Math.floor((value.min-0)/5)*5 : 0 },
            //max: function (value) { return value.max>1 ? Math.ceil((value.max-0)/5)*5 : 50 },
            min: function (value) { return value.min>3 ? Math.round(value.min - 3) : 0 },
            max: function (value) { return Math.round(value.max + 3) },
            minInterval: 2,
        }, {
            gridIndex: 1,
            name: L.illuminance,
            nameGap: 6,
            type: 'value',
            max: function (value) { return value.max > 5 ? (Math.ceil(value.max/5))*5 : 5; },
            min: 0,
            minInterval: 1,
            axisLine: {
                show: true,
                //lineStyle: { color: colors[2] }
            },
            splitLine: { show: false, },
            splitNumber: 2,
            axisLabel: { 
                //formatter: '{value} lx'
                formatter: (value, idx)=>{ 
                    let c = Math.round(value*1)/1;
                    if (value===c)//(Math.abs(value - c) < 0.01)
                        return c + 'lx'; 
                    else
                        return null;
                }
            }
        }],
        tooltip: {
            trigger: 'axis',
            confine: true,
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            formatter: function (params) {
                let readings = [
                    params[0].value[1] !==null ? Math.round(params[0].value[1]*10)/10 + '°C' : '无',
                    params[0].value[2] !==null ? Math.round(params[0].value[2]) + '%' : '无',
                    params[0].value[3] !==null ? Math.round(params[0].value[3]*10)/10 + 'lx' : '无'
                ];
                return formatDateString(params[0].value[0]*1000, "HHmm") + '\n'
                    + params[0].marker + params[0].seriesName + '  ' + readings[params[0].seriesIndex] + '\n'
                    + params[1].marker + params[1].seriesName + '  ' + readings[params[1].seriesIndex] + '\n'
                    + params[2].marker + params[2].seriesName + '  ' + readings[params[2].seriesIndex];
            },
        },
        series: [{
            name: L.temperature,
            type: 'line',
            data: data,
            itemStyle: { color: colors[0] },
            xAxisIndex: 0,
            yAxisIndex: 0,
            encode: {
                x: 0,
                y: 1,
            },
            symbol: function(value, params) { return getSymbol(value, params, data) },
            markArea: {
                silent: false,
                label: {
                    show: false,
                },
                itemStyle: {
                    color: getColor('tag'),
                    opacity: 0.4,
                },
                emphasis: {
                    label: {
                        show: true,
                        position: 'insideTop',
                        color: getColor('text'),    
                    }
                },
                data: dataMask,
            }
        }, {
            name: L.humidity,
            type: 'line',
            data: data,
            itemStyle: { color: colors[1] },
            xAxisIndex: 0,
            yAxisIndex: 1,
            encode: {
                x: 0,
                y: 2,
            },
            symbol: function(value, params) { return getSymbol(value, params, data) },
        }, {
            name: L.illuminance,
            type: 'line',
            data: data,
            areaStyle: { color: colors[2] },
            itemStyle: { color: colors[2] },
            xAxisIndex: 1,
            yAxisIndex: 2,
            encode: {
                x: 0,
                y: 3,
            },
            symbol: function(value, params) { return getSymbol(value, params, data) },
        }]
      };
    env_chart.setOption(option);
}

function setDayEnvData(data) {
    env_data = data;
}

