<!-- Sleep Trend Analysis Component -->
<template>
  <div class="sleep-trend-analysis">
    <!-- Loading 遮罩层 -->
    <div class="loading-overlay" v-if="loading">
      <van-loading type="spinner" size="36px" color="#1989fa">加载中...</van-loading>
    </div>    
    <template v-else>
      <div class="navigation-bar">
        <van-button size="small" plain @click="navigateToPrev" :disabled="activeTab === 'year' ? true : false">
  前一{{ activeTab === 'year' ? '年' : '月' }}
</van-button>  
  <div class="center-buttons">
      <van-button 
        class="tab-button left-button" 
        :class="{ active: activeTab === 'month' }"
        :disabled="activeTab === 'month'"

        plain
        size="small"
        @click="setActiveTab('month')"
      >月</van-button>
      <van-button 
        class="tab-button right-button" 
        :class="{ active: activeTab === 'year' }"
        :disabled="activeTab === 'year'"

        plain
        size="small"
        @click="setActiveTab('year')"
      >年</van-button>
    </div>
    <van-button 
  size="small" 
  plain 
  @click="navigateToNext" 
  :disabled="activeTab === 'year' ? true : isNextDisabled"
>
  后一{{ activeTab === 'year' ? '年' : '月' }}
</van-button>
  </div>
      <!-- 睡眠分析 -->
      <div class="analysis-block">
        <div class="block-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/Bed Solid.svg" alt="睡眠图标">
            <span class="block-title">睡眠分析</span>
          </div>
        </div>

        <div class="chart-item">
          <div class="chart-label">总睡眠时长</div>
          <div class="chart-box">
            <div class="total-sleep-chart" ref="totalSleepChart">
              <div class="tst-chart" :id="TstChartId"></div>
            </div>
          </div>
        </div>

        <div class="chart-item">
          <div class="chart-label">入睡速度</div>
          <div class="chart-box">
            <div class="sleep-speed-chart" ref="sleepSpeedChart">           
              <div class="sol-chart" :id="SolChartId"></div>
            </div>
          </div>
        </div>

        <div class="chart-item">
          <div class="chart-label">各睡眠阶段时长</div>
          <div class="chart-box">
            <div class="sleep-stages-chart" ref="sleepStagesChart">
              <div class="stage-chart" :id="StageCompChartId"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 心率变异性 -->
      <div class="analysis-block">
        <div class="block-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/Heart Pulse Icon.svg" alt="心率图标">
            <span class="block-title">心率变异性 (HRV)</span>
          </div>
        </div>

        <div class="chart-item">
          <div class="chart-label">睡眠平均心率变异性</div>
          <div class="chart-box">
            <div class="hrv-chart" ref="hrvChart">
              <div class="hrv-chart" :id="HrvChartId"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 呼吸健康 -->
      <div class="analysis-block">
        <div class="block-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/Lungs Solid.svg" alt="呼吸图标">
            <span class="block-title">呼吸健康</span>
          </div>
        </div>

        <div class="chart-item">
          <div class="chart-label">呼吸暂停低通气指数（AHI）</div>
          <div class="chart-box">
            <div class="breathing-chart" ref="breathingChart">
              <div class="ahi-chart" :id="AHiChartId"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, computed, watch, onUnmounted } from 'vue'
import * as echarts from 'echarts'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { createAxiosInstance, request_data } from "@/plugins/axios.js"
import { setTSTChartOption } from "@/plugins/chart/tst_trend_chart"
import { resetDates } from "@/plugins/chart/variables"
import { formatDateString } from "@/plugins/utils/utils"
import { setSOLChartOption } from "@/plugins/chart/sol_trend_chart"
import{setStageCompChartOption} from "@/plugins/chart/stage_comp_chart"
import {setHRVChartOption} from "@/plugins/chart/hr_chart_v2";
// import {setAHIChartOption} from "@/plugins/chart/ahi_month_chart";
import{setAHIChartOption} from "@/plugins/chart/ahi_trend_chart"
import { showToast } from 'vant'
const report_start=ref('')
const report_end=ref('')
const report_date=ref('')

// Props 定义
const props = defineProps({
  deviceId: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  }
})

// 状态定义
const router = useRouter()
const loading = ref(false)
const activeTab = ref('month')
const reports_data = ref(null)
const reportCount = ref(0)
const chart_dates = ref({})
const currentDate = ref(new Date())
// 将计算属性改为 ref
const isNextDisabled = ref(false)
if(activeTab.value === 'year') {
  isNextDisabled.value = false
} 
// 创建一个计算函数来更新 isNextDisabled
// 计算当前是否为最新月份
const updateNextDisabled = () => {
  const today = dayjs()
  const current = dayjs(currentDate.value)
  isNextDisabled.value = current.format('YYYY-MM') === today.format('YYYY-MM')
}

async function setActiveTab(tab) {
  activeTab.value = tab
  fetchSleepTrendData()
  // 清空图表
}
// 在需要更新时调用

// 图表相关
const charts = ref({
  tst: null,
  sol: null,
  totalSleep: null,
  sleepSpeed: null,
  sleepStages: null,
  hrv: null,
  breathing: null,
  stage_comp: null
})
const TstChartId = ref(`ahi_chart_${Math.random().toString(36).substr(2, 9)}`);
const SolChartId = ref(`sol_chart_${Math.random().toString(36).substr(2, 9)}`);
const StageCompChartId = ref(`stage_comp_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrvChartId = ref(`hrv_chart_${Math.random().toString(36).substr(2, 9)}`);
const AHiChartId = ref(`ahi_chart_${Math.random().toString(36).substr(2, 9)}`);

const chartIds = {
  tst: `tst_chart_${Math.random().toString(36).substr(2, 9)}`,
  sol: `sol_chart_${Math.random().toString(36).substr(2, 9)}`,
  stageComp: `stage_comp_chart_${Math.random().toString(36).substr(2, 9)}`,
  hrv: `hrv_chart_${Math.random().toString(36).substr(2, 9)}`,
  ahi: `ahi_chart_${Math.random().toString(36).substr(2, 9)}`
}

// 计算属性
const getPrevButtonText = computed(() => {
  const texts = {
    month: '前一月',
    year: '前一年'
  }
  return texts[activeTab.value] || '前一月'
})

const getNextButtonText = computed(() => {
  const texts = {
    month: '后一月',
    year: '后一年'
  }
  return texts[activeTab.value] || '后一月'
})

// 日期处理函数
const formatDate = (date) => {
  const d = new Date(date)
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
}
// 检查数据是否有效
const hasValidData = (data) => {
  if (!data) return false;
  // 检查是否有任何一天的数据不是空对象
  return Object.values(data).some(dayData => {
    return Object.keys(dayData).length > 0;
  });
}
// 数据获取
async function getReportList(reportdate) {
  try {
    if (!reportdate || !props.deviceId) {
      console.error('Invalid parameters')
      return
    }

    const selectedDate = new Date(reportdate)
    if (isNaN(selectedDate.getTime())) {
      console.error('Invalid report date:', reportdate)
      return
    }

    const endDate = new Date(selectedDate)
    const startDate = new Date(selectedDate)
    if (activeTab.value === 'month') {
      startDate.setDate(selectedDate.getDate() - 29)
    } else if (activeTab.value === 'year') {
      startDate.setDate(selectedDate.getDate() - 120)
    }
    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatDate(endDate)

    const response = await request_data(
      `/deviceallreport/${props.deviceId}/${formattedStartDate}/${formattedEndDate}/?uat=1`
    )

    if (response) {
      if (!hasValidData(response)) {
        showToast('没有数据')
        console.log('没有数据')
        return
      }
      console.log('有数据')
      report_date.value=formatDate(new Date(selectedDate).setDate(selectedDate.getDate()+29))
      reports_data.value = response
      report_start.value=formattedStartDate
      report_end.value=formattedEndDate
      await parseReportsToDisplay(response)
      return response
    }
  } catch (error) {
    console.error('Error in getReportList:', error)
  }
}
const parseReportsToDisplay = async (reports) => {
  if (!reports || Object.keys(reports).length === 0) return

  reportCount.value = Object.keys(reports).length

  const max_chart_days = {
    week: 7,
    month: 30,
    year: 365
  }[activeTab.value] || 30

  // 通过日期排序找到最新的报告
  const sortedDates = Object.keys(reports).sort((a, b) => {
    const [yearA, monthA, dayA] = a.split('/').map(Number)
    const [yearB, monthB, dayB] = b.split('/').map(Number)
    const dateA = new Date(yearA, monthA - 1, dayA)
    const dateB = new Date(yearB, monthB - 1, dayB)
    return dateB - dateA
  })
  const latestDate = sortedDates[0]
  const latestReport = reports[latestDate]

  // 以最新报告日期作为今天
  const [latestYear, latestMonth, latestDay] = latestDate.split('/').map(Number)
  const today = new Date(latestYear, latestMonth - 1, latestDay)
  today.setHours(0, 0, 0, 0)
  let todayTS = today.getTime() / 1000

  resetDates()
  chart_dates.value = {}

  // 生成日期序列
  for (let i = 0; i < max_chart_days; i++) {
    const date = new Date(todayTS * 1000)
    const year = date.getFullYear()
    const month = date.getMonth() + 1  // 不补零
    const day = date.getDate()         // 不补零
    const formattedDate = `${year}/${month}/${day}`
    chart_dates.value[formattedDate] = -1
    todayTS -= 24 * 3600
  }

  // 填充报告数据
  Object.entries(reports).forEach(([dateKey, report]) => {
    if (chart_dates.value[dateKey] === -1) {
      chart_dates.value[dateKey] = dateKey
    } else if (chart_dates.value[dateKey]) {
      const existingKey = chart_dates.value[dateKey]
      if (reports[existingKey].report_time < report.report_time) {
        chart_dates.value[dateKey] = dateKey
      }
    }
  })

  // 清理开头的空白段（保留最后14天，与小程序逻辑一致）
  const keysDate = Object.keys(chart_dates.value).reverse()
  for (let i = 0; i < keysDate.length; i++) {
    if (chart_dates.value[keysDate[i]] !== -1) break
    if (i < keysDate.length - 14) {  // 改为保留14天空白
      delete chart_dates.value[keysDate[i]]
    }
  }

}

// 解析报告数据
const parseReportsToDisplay1 = async (reports) => {
  if (!reports || Object.keys(reports).length === 0) return

  reportCount.value = Object.keys(reports).length

  const max_chart_days = {
    week: 7,
    month: 30,
    year: 365
  }[activeTab.value] || 30
  // 找到最新的报告日期
  const latestReport = Object.values(reports)[0]
  
  const today = new Date(latestReport.end * 1000)
  today.setHours(0, 0, 0, 0)
  let todayTS = today.getTime() / 1000

  resetDates()
  chart_dates.value = {}

  // 生成日期序列
  for (let i = 0; i < max_chart_days; i++) {
    const date = new Date(todayTS * 1000);
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const shortDate = `${month}/${day}`;
    chart_dates.value[shortDate] = -1;
    todayTS -= 24 * 3600;
  }
  
  // 填充报告数据
  Object.entries(reports).forEach(([key, report]) => {
    const date = new Date(report.end * 1000);
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const shortDate = `${month}/${day}`;
    if (chart_dates.value[shortDate] === -1) {
      chart_dates.value[shortDate] = key;
    } else if (chart_dates.value[shortDate] >= 0) {
      const existingKey = chart_dates.value[shortDate];
      if (reports[existingKey].report_time < report.report_time) {
        chart_dates.value[shortDate] = key;
      }
    }
  })

  // 清理开头的空白段
  const keysDate = Object.keys(chart_dates.value).reverse()
  for (let i = 0; i < keysDate.length; i++) {
    if (chart_dates.value[keysDate[i]] >= 0) break
    if (i < keysDate.length - 29) {
      delete chart_dates.value[keysDate[i]]
    }
  }
  // 清理结尾的空白段
}

// 添加图表加载状态跟踪
const chartsLoaded = ref({
  tst: false,
  sol: false,
  stageComp: false,
  hrv: false,
  ahi: false
})

// 修改 updateTSTChart 函数
async function updateTSTChart() {
  try {
    await nextTick()
    const tst_canvas = document.getElementById(TstChartId.value)
    
    if (!tst_canvas) {
      console.error('TST canvas element not found')
      chartsLoaded.value.tst = true // 即使失败也标记完成
      return
    }

    if (!reports_data.value) {
      console.error('No report data available')
      chartsLoaded.value.tst = true
      return
    }

    if (charts.value.tst) {
      charts.value.tst.dispose()
    }

    const tst_chart = echarts.init(tst_canvas)
    charts.value.tst = tst_chart
    await parseReportsToDisplay(reports_data.value)
    setTSTChartOption(reports_data.value, tst_chart, chart_dates.value)
    
    chartsLoaded.value.tst = true
  } catch (error) {
    console.error('Error updating TST chart:', error)
    chartsLoaded.value.tst = true
  }
}

// 类似地修改其他图表更新函数...
// updateSOLChart, updateStageCompChart, updateHrvChart, updateAhiChart

// 修改 fetchSleepTrendData 函数
async function fetchSleepTrendData() {
  loading.value = true
  try {
    Object.keys(chartsLoaded.value).forEach(key => {
      chartsLoaded.value[key] = false
    })

    await getReportList(props.date)
    await nextTick()
    
    // 确保 DOM 完全渲染后再初始化图表
    setTimeout(async () => {
      try {
        await Promise.all([
          updateTSTChart(),
          updateSOLChart(),
          updateStageCompChart(),
          updateHrvChart(),
          updateAhiChart()
        ])
      } catch (error) {
        console.error('Error initializing charts:', error)
      }
    }, 100)
  } catch (error) {
    console.error('获取数据失败:', error)
  } finally {
    loading.value = false
  }
}

// 监听属性变化
watch(() => props.date, fetchSleepTrendData)
watch(() => activeTab.value, fetchSleepTrendData)

// 生命周期钩子
onMounted(async () => {
  updateNextDisabled()
  await fetchSleepTrendData()
})

onUnmounted(() => {
  // 删除这部分代码
})

// 暴露需要的方法（如果需要）
defineExpose({
  refreshData: fetchSleepTrendData,
  charts: charts
})

async function updateSOLChart() {
  try {
    await nextTick()
    const sol_canvas = document.getElementById(SolChartId.value)
    
    if (!sol_canvas) {
      console.error('SOL canvas element not found')
      chartsLoaded.value.sol = true
      return
    }

    if (!reports_data.value) {
      console.error('No report data available')
      chartsLoaded.value.sol = true
      return
    }

    if (charts.value.sol) {
      charts.value.sol.dispose()
    }

    const sol_chart = echarts.init(sol_canvas)
    charts.value.sol = sol_chart
    await parseReportsToDisplay(reports_data.value)
    setSOLChartOption(reports_data.value, sol_chart, chart_dates.value)
    
    chartsLoaded.value.sol = true
  } catch (error) {
    console.error('Error updating SOL chart:', error)
    chartsLoaded.value.sol = true
  }
}

async function updateStageCompChart() {
  try {
    await nextTick()
    const stage_comp_canvas = document.getElementById(StageCompChartId.value)
    
    if (!stage_comp_canvas) {
      console.error('Stage Comp canvas element not found')
      chartsLoaded.value.stageComp = true
      return
    }

    if (!reports_data.value) {
      console.error('No report data available')
      chartsLoaded.value.stageComp = true
      return
    }

    if (charts.value.stage_comp) {
      charts.value.stage_comp.dispose()
    }

    const stage_comp_chart = echarts.init(stage_comp_canvas)
    charts.value.stage_comp = stage_comp_chart
    await parseReportsToDisplay(reports_data.value)
    setStageCompChartOption(reports_data.value, stage_comp_chart, chart_dates.value)
    
    chartsLoaded.value.stageComp = true
  } catch (error) {
    console.error('Error updating Stage Comp chart:', error)
    chartsLoaded.value.stageComp = true
  }
}

async function updateHrvChart() {
  try {
    await nextTick()
    const hrv_canvas = document.getElementById(HrvChartId.value)
    
    if (!hrv_canvas) {
      console.error('HRV canvas element not found')
      chartsLoaded.value.hrv = true
      return
    }

    if (!reports_data.value) {
      console.error('No report data available')
      chartsLoaded.value.hrv = true
      return
    }

    if (charts.value.hrv) {
      charts.value.hrv.dispose()
    }

    const hrv_chart = echarts.init(hrv_canvas)
    charts.value.hrv = hrv_chart
    await parseReportsToDisplay(reports_data.value)
    setHRVChartOption(reports_data.value, hrv_chart, chart_dates.value)
    
    chartsLoaded.value.hrv = true
  } catch (error) {
    console.error('Error updating HRV chart:', error)
    chartsLoaded.value.hrv = true
  }
}

async function updateAhiChart() {
  try {
    await nextTick()
    const ahi_canvas = document.getElementById(AHiChartId.value)
    
    if (!ahi_canvas) {
      console.error('AHI canvas element not found')
      chartsLoaded.value.ahi = true
      return
    }

    if (!reports_data.value) {
      console.error('No report data available')
      chartsLoaded.value.ahi = true
      return
    }

    if (charts.value.ahi) {
      charts.value.ahi.dispose()
    }

    const ahi_chart = echarts.init(ahi_canvas)
    charts.value.ahi = ahi_chart
    await parseReportsToDisplay(reports_data.value)
    setAHIChartOption(reports_data.value, ahi_chart, chart_dates.value)
    
    chartsLoaded.value.ahi = true
  } catch (error) {
    console.error('Error updating AHI chart:', error)
    chartsLoaded.value.ahi = true
  }
}

// 导航函数
const navigateToPrev = async () => {
  if (!reports_data.value || Object.keys(reports_data.value).length === 0) {
    showToast('没有数据')
    return
  }
  
  const result = await getReportList(report_start.value)
  
  if (!result || !hasValidData(result)) {
    showToast('没有更多数据')
    return
  }
  
  reports_data.value = result
  await nextTick()
  initCharts()
  // 成功导航到前一个月并渲染图表后，启用后一个月按钮
  currentDate.value = dayjs(report_start.value).toDate()
  isNextDisabled.value = false
}

const navigateToNext = async () => {
  if (!reports_data.value || Object.keys(reports_data.value).length === 0) {
    showToast('没有数据')
    return
  }
  const result = await getReportList(report_date.value)
  if (!result || !hasValidData(result)) {
    showToast('没有更多数据')
    return
  }
  
  reports_data.value = result
  await nextTick()
  initCharts()
      
  // 更新当前日期为查询的日期
  currentDate.value = dayjs(report_end.value).toDate()
  // 检查是否需要禁用下一月按钮
  updateNextDisabled()
}

// 图表初始化和更新
async function initCharts() {
  await nextTick()
  updateTSTChart()
  updateSOLChart()
  updateStageCompChart()
  updateHrvChart()
  updateAhiChart()
}
</script>

<style scoped>
.sleep-trend-analysis {
  padding: 16px;
  background-color: #f5f5f5;
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 16px;
}

.center-buttons {
  display: flex;
  align-items: center;
}

.tab-button {
  border: 1px solid #dcdee0 !important;
  background: #fff !important;
  color: #666 !important;
  min-width: 60px !important;
  margin: 0 !important; /* 移除默认的margin */
}

.left-button {
  border-radius: 4px 0 0 4px !important;
  border-right: none !important;
}

.right-button {
  border-radius: 0 4px 4px 0 !important;
}

.tab-button.active {
  color: #1989fa !important;
  border-color: #1989fa !important;
  background: #fff !important;
  font-weight: 500;
  z-index: 1; /* 确保活动按钮的边框在上层 */
}

/* 当左按钮激活时，确保右边框显示 */
.left-button.active {
  border-right: 1px solid #1989fa !important;
}

/* 当右按钮激活时，确保左边框显示 */
.right-button.active {
  border-left: 1px solid #1989fa !important;
}

/* 可选：添加hover效果 */
.tab-button:hover:not(.active) {
  border-color: #1989fa !important;
  color: #1989fa !important;
  z-index: 1;
}
.analysis-block {
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
}

.block-header {
  margin-bottom: 16px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.module-icon {
  width: 20px;
  height: 20px;
}

.block-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.chart-item {
  margin-bottom: 16px;
}

.chart-item:last-child {
  margin-bottom: 0;
}

.chart-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.chart-box {
  width: 100%;
  background: white;
}

.tst-chart,
.sol-chart,
.stage-chart,
.hrv-chart,
.ahi-chart {
  width: 100%;
  height: 150px;
}
.loading-overlay {
  position: fixed;  /* 已经是fixed，这个不用改 */
  top: 0;          /* 已经正确 */
  left: 0;         /* 已经正确 */
  right: 0;        /* 已经正确 */
  bottom: 0;       /* 已经正确 */
  background-color: rgba(255, 255, 255, 0.5);  /* 改为0.5透明度使其半透明 */
  display: flex;   /* 已经正确 */
  justify-content: center;  /* 已经正确 */
  align-items: center;      /* 已经正确 */
  z-index: 999;    /* 已经正确 */
  animation: fadeIn 0.3s ease;  /* 已经正确 */
}
</style>
