import { getColor, getRankColor, seconds2HHmm, formatDateString } from '@/plugins/utils/utils';

import { locales } from '@/plugins/utils/locales';
const L = locales['zh-Hans']

const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

let tst_chart   = null;
let se_chart = null;
let tst_data = null;
let se_data = null;


import {
    chart_dates, // 图表横轴的日期与报告数据之间的映射关系
    getInterval
  } from './variables.js';


function getSymbol(value, params, data, icon) { //单点数据需要显示symbol，能连成线则不显示
    var left = params.dataIndex -1;
    var right = params.dataIndex +1;
    var symbol = 'none';
    if (left>=0 && right<data.length) { //中间单点
        if (data[left][1]==null && data[right][1]==null) {
            symbol = icon || 'circle';
        }
    } else if (right<data.length) { //最左边缘
        if (data[right][1]==null) symbol = icon || 'circle';
    } else if (left>=0) { //最右边缘
        if (data[left][1]==null) symbol = icon || 'circle';
    }
    return symbol;
}

export function setTSTChartOption(reports,tst_chart,chart_dates) {
    tst_data = reports;
    if (!tst_chart) {
        return;
    }

    var dataTST = [];
    var dataTIB = [];
    var dataXAxis = [];

    //从日期序列转换到图表数据
    var weekmode = false;
    if (Object.keys(reports).length > 0) {
        const total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        var tst, tib, start, end;

        if (total<=60) {
            for (var key in chart_dates) {
                const [year,month, day] = key.split('/');
                const abbrKey = month + '-' + day;
        
                if (reports[key] && Object.keys(reports[key]).length > 0) {
                  tst = Math.round(reports[key].tst);
                  tib = Math.round(reports[key].tib);
                  start = Math.round(reports[key].start);
                  end = Math.round(reports[key].end);
                } else {
                  tst = null;
                  tib = null;
                  start = null;
                  end = null;
                }
        
                if (!tib || tib>=24*60) { //数据非法
                    tib = (end - start)/60;
                }
                if (!tst) tst = null;
                if (!tib) tib = null;
                let c = getRankColor(reports[chart_dates[key]], 'tst', tst);
                dataTST.push({ name: abbrKey, value: tst, itemStyle: { color: c } });
                dataTIB.push({ name: abbrKey, value: tib });
                dataXAxis.push( abbrKey );
            }
        } else {
            weekmode = true;
            let [tst_avg, tib_avg, count] = [0, 0, 0];
            let valid = false; // 跳过开头的“半周”
            let weekend = '';
            let birthdate = null;
            for (let key in chart_dates) {
                let bd = chart_dates[key] >=0 ? reports[chart_dates[key]].birthdate : null;
                if (bd) { 
                    let bdarr = bd.split('/');
                    if (bdarr.length==2) {
                        let d = new Date();
                        d.setFullYear(bdarr[0], bdarr[1] - 1, 15);
                        d.setHours(0, 0, 0, 0);
                        birthdate = d;
                    }
                    break; 
                }
            }

            for (let key in chart_dates) {
                let [year, month, day] = key.split('/');
                let d = new Date();
                d.setHours(0, 0, 0, 0);
                d.setFullYear(year, month - 1, day);
                let weekday = d.getDay();
        
                if (reports[key] && Object.keys(reports[key]).length > 0) {
                    tst = Math.round(reports[key].tst);
                    tib = Math.round(reports[key].tib);
                    start = Math.round(reports[key].start);
                    end = Math.round(reports[key].end);
                  } else {
                    tst = null;
                    tib = null;
                    start = null;
                    end = null;
                  }
          
                if (!tib || tib>=24*60) { //数据非法
                    tib = (end - start)/60;
                }
                if (tst!=null && tib!=null && valid) {
                    tst_avg += tst;
                    tib_avg += tib;
                    count++;
                }
                if (weekday===6) { //每周最后一天
                    weekend = month+'-'+day;
                }
                if (weekday===0) { //每周第一天
                    if (valid) {
                        tst_avg = count ? Math.round(tst_avg / count) : null;
                        tib_avg = count ? Math.round(tib_avg / count) : null;
                        key = month+'-'+day + L.to + weekend;

                        let age = birthdate ? Math.round(100*(d - birthdate)/(86400*1000*365))/100 : 38;
                        let c = getRankColor(null, 'tst', tst_avg, age);
                        dataTST.push({ name: key, value: tst_avg, itemStyle: { color: c } });
                        dataTIB.push({ name: key, value: tib_avg });
                        dataXAxis.push( key );
                        [tst_avg, tib_avg, count] = [0, 0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
        }
    }
    dataTST.reverse();
    dataTIB.reverse();
    dataXAxis.reverse();

    var option = {
        animationDuration: 500,
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesDesc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            axisPointer: {
                type: 'line',
                label: { 
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value) => { 
                if (value) {
                    return seconds2HHmm(value*60, true) ;
                } else {
                    return '-';
                }
            }
        },
        /*legend: {
            data: [{ name: L.total_sleep_time }, { name: L.time_in_bed, icon: 'triangle' } ],
            orient: 'horizontal',
            bottom: 1,
            selectedMode: true,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 12,
            itemGap: 6,
            textStyle: {
                fontSize: 12,
                padding: [0, 0, 0, -2],
            },
        },*/
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: { alignWithLabel: true },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 :1,
                formatter: (value)=>{
                    if (weekmode) {
                        let [weekstart, weekend] = value.split(L.to);
                        let [startMonth, startDay] = weekstart.split('-');
                        let [endMonth, endDay] = weekend.split('-');
                        if (startMonth!=endMonth || startDay==1) { // 月初
                            return monthNameArray[ parseInt(endMonth) - 1 ];
                        } else { return '' }
                    } else { return value; }
                }
            },
            data: dataXAxis,
        }],
        yAxis:{
            type: 'value',
            minInterval: 60,
            min: function (value) { return Math.floor(value.min/120)*120 },
            axisLabel: {
                show: true,
                formatter: (value, index)=>{
                    return Math.round(value/60) + L.time_hr;
                }
            },
        },
        series: [/*{
            name: L.time_in_bed,
            type: 'line',
            smooth: true,
            //symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataTIB); },
            symbol: 'triangle',
            symbolSize: 6,
            itemStyle: { color: '#73c0de' },
            data: dataTIB,
        }, */{
            name: L.total_sleep_time,
            type: 'line',
            smooth: true,
            //symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataTST); },
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: { color: getColor('linegray1') },
            itemStyle: { color: getColor('linegray1') },
            data: dataTST,
        }]
    };
    tst_chart.setOption(option);
}
