<!-- Marker.vue -->
<template>
    <div class="markerbox" :style="customStyle">
      <!-- 指示器 -->
      <div 
        v-if="high > low && value && pos !== null" 
        class="triangle2" 
        :style="{ left: pos + '%' }"
      />
      
      <!-- 进度条 -->
      <div class="gray_bar">
        <div 
          v-if="high > low" 
          class="color_bar" 
          style="left: 33.3%; width: 33.3%;"
        />
        <div 
          v-if="labelEmpty && !(high > low)" 
          class="empty_text"
        >
          {{ labelEmpty }}
        </div>
      </div>
      
      <!-- 标签 -->
      <div 
        v-if="high > low" 
        class="label_text" 
        style="left: 33.3%;"
      >
        {{ labelLow }}
      </div>
      <div 
        v-if="high > low" 
        class="label_text" 
        style="left: 66.7%;"
      >
        {{ labelHigh }}
      </div>
      
      <!-- 插槽 -->
      <slot />
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue'
  
  // 定义props
  const props = defineProps({
    customStyle: {
      type: String,
      default: ''
    },
    low: {
      type: Number,
      default: 0
    },
    high: {
      type: Number,
      default: 0
    },
    labelLow: {
      type: String,
      default: ''
    },
    labelHigh: {
      type: String,
      default: ''
    },
    labelEmpty: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    }
  })
  
  // 计算指示器位置
  const pos = ref(null)
  
  // 监听值变化
  watch(
    () => [props.value, props.high, props.low],
    () => {
      if (!props.high || !props.low) return
      
      let position = Math.round((props.value - 2 * props.low + props.high) * 1000 / (props.high - props.low) / 3) / 10
      
      if (position > 100) position = 100
      if (position < 0) position = 0
      
      pos.value = position
    },
    { immediate: true }
  )
  </script>
  
  <style scoped>
  .markerbox {
    position: relative; 
    padding-top: 8px;
    padding-bottom: 24px; 
    min-width: 82vw;
  }
  
  .gray_bar {
    background-color: var(--marker-background-color, #E5E5E5);
    border-radius: 5px;
    height: 10px;
    position: relative;
  }
  
  .color_bar {
    background-color: #8DD783;
    height: 100%;
    position: relative;
  }
  
  /* 亮色主题 */
  @media (prefers-color-scheme: light) {
    .color_bar {
      background-image: repeating-linear-gradient(
        -45deg, 
        transparent, 
        transparent 2px, 
        rgba(255,255,255,.5) 2px, 
        rgba(255,255,255,.5) 4px
      );
    }
  }
  
  /* 暗色主题 */
  @media (prefers-color-scheme: dark) {
    .color_bar {
      background-image: repeating-linear-gradient(
        -45deg, 
        transparent, 
        transparent 2px, 
        rgba(0,0,0,.5) 2px, 
        rgba(0,0,0,.5) 4px
      );
    }
    
    .gray_bar {
      background-color: var(--marker-background-color, #333);
    }
  }
  
  .triangle2 {
    width: 6px;
    height: 20px;
    background-color: var(--marker-marker-color, #666);
    border-radius: 3px;
    position: absolute;
    top: 3px;
    transform: translate(-50%, 0);
    z-index: 10;
    transition: left 0.3s ease;
  }
  
  .label_text {
    font-size: 14px;
    color: var(--cell-value-color, #969799);
    line-height: 20px;
    margin-top: 6px;
    white-space: nowrap;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    transform: translate(-50%, 0);
  }
  
  .empty_text {
    font-size: 8px;
    color: var(--cell-background-color, #999);
    line-height: 1em;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  </style>