<template>
  <el-dialog
    v-model="visible"
    title="编辑信息"
    width="50%"
    @close="handleClose"
  >
    <div class="space-y-4">
      <el-form :model="form" label-width="100px">
        <!-- 一行：名、性别、民族 -->
        <div class="flex gap-4">
          <el-form-item label="姓名:" class="w-64">
            <el-input v-model="form.name" placeholder="请输入姓名" />
          </el-form-item>
          
          <el-form-item label="性别:" class="w-48">
            <el-select 
              v-model="form.gender"
              placeholder="请选择..."
              class="w-full"
            >
              <el-option label="男" value="男" />
              <el-option label="女" value="女" />
            </el-select>
          </el-form-item>

          <el-form-item label="民族:" class="flex-1">
            <el-select 
              v-model="form.ethnicity"
              filterable
              placeholder="请选择或搜索民族"
              class="w-full"
            >
              <el-option
                v-for="ethnic in ethnicities"
                :key="ethnic"
                :label="ethnic"
                :value="ethnic"
              />
            </el-select>
          </el-form-item>
        </div>

        <!-- 第二行：出生日期、年龄 -->
        <div class="flex gap-4">
          <el-form-item label="出生日期:" class="w-64">
            <el-date-picker
              v-model="form.birthDate"
              type="date"
              @update:modelValue="calculateAge"
              placeholder="选择日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              class="w-full"
              :clearable="false"
              :disabled-date="disabledDate"
              :shortcuts="shortcuts"
              :locale="zhCn"
            />
          </el-form-item>

          <el-form-item label="年龄:" class="w-48">
            <span class="text-gray-500">{{ form.age || '--' }} 岁</span>
          </el-form-item>
        </div>

        <!-- 第三行：身高、体重、BMI -->
        <div class="flex gap-4">
          <el-form-item label="身高:" class="w-1/3">
            <div class="flex items-center">
              <el-input-number
                v-model="form.height"
                :min="0"
                @change="calculateBMI"
                class="w-28"
                controls-position="right"
              />
              <span class="text-gray-500 ml-2">cm</span>
            </div>
          </el-form-item>

          <el-form-item label="体重:" class="w-1/3">
            <div class="flex items-center">
              <el-input-number
                v-model="form.weight"
                :min="0"
                @change="calculateBMI"
                class="w-28"
                controls-position="right"
              />
              <span class="text-gray-500 ml-2">kg</span>
            </div>
          </el-form-item>

          <el-form-item label="BMI:" class="w-1/3">
            <span class="text-gray-500">{{ form.bmi || '--' }}</span>
          </el-form-item>
        </div>
        
        <!-- 自定义字段 -->
        <div v-for="(field, index) in customFields" :key="index">
          <el-form-item :label="field.isEditing ? '字段名称' : field.label || '字段名称'">
            <div class="flex flex-col gap-2">
              <!-- 添加错误提示区域 -->
              <div v-if="field.error" class="text-red-500 text-sm mb-1">
                {{ field.error }}
              </div>
              
              <div class="flex items-center gap-2">
                <template v-if="!field.label || field.isEditing">
                  <el-input 
                    v-model="field.tempLabel" 
                    placeholder="请输入字段名称"
                    class="w-1/3"
                    @input="field.error = ''"
                  />
                  <el-input 
                    v-model="field.value.value" 
                    placeholder="请输入值"
                    class="w-1/3"
                    @input="field.error = ''"
                  />
                  <el-input 
                    v-model="field.value.unit" 
                    placeholder="单位"
                    class="w-24"
                  />
                  <button 
                    type="button"
                    @click="onFieldLabelConfirm(field, index)"
                    class="text-blue-500 hover:text-blue-600 p-1.5"
                    title="确认"
                  >
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </button>
                </template>
                
                <template v-else>
                  <div class="flex items-center gap-2 flex-1">
                    <el-input 
                      v-model="field.value.value" 
                      :placeholder="`请输入${field.label}的值`"
                      class="w-1/3"
                    />
                    <span class="text-gray-500">{{ field.value.unit }}</span>
                  </div>
                  <button 
                    @click="editFieldLabel(field)"
                    class="text-blue-500 hover:text-blue-600 p-1.5"
                    title="编辑字段名称"
                  >
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                </template>
                <button 
                  @click="removeCustomField(index)" 
                  class="text-red-500 hover:text-red-600 p-1.5"
                  title="删除字段"
                >
                  <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </el-form-item>
        </div>
        
        <!-- 添加自定义字段按钮 -->
        <el-button 
          type="primary" 
          @click="addCustomField"
          class="mt-4"
        >
          <span class="flex items-center gap-1">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            添加自定义字段
          </span>
        </el-button>
      </el-form>
    </div>
    
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { ElMessage } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const props = defineProps({
  modelValue: Boolean,
  patientInfo: {
    type: Object,
    default: () => ({})
  },
  customFields: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue', 'save'])

const visible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const form = ref({
  name: '',
  gender: '',
  ethnicity: '',
  birthDate: '',
  age: '',
  height: null,
  weight: null,
  bmi: '',
})

const customFields = ref([])

// 民族列表
const ethnicities = [
    "汉族", "蒙古族", "回族", "藏族", "维吾尔族",
    "苗族", "彝族", "壮族", "布依族", "朝鲜族",
    "满族", "侗族", "瑶族", "白族", "土家族",
    "哈尼族", "哈萨克族", "傣族", "黎族", "傈僳族",
    "佤族", "畲族", "高山族", "拉祜族", "水族",
    "东乡族", "纳西族", "景颇族", "柯尔克孜族", "土族",
    "达斡尔族", "仫佬族", "羌族", "布朗族", "撒拉族",
    "毛南族", "仡佬族", "锡伯族", "阿昌族", "普米族",
    "塔吉克族", "塔塔尔族", "乌孜克族", "俄罗斯族", "鄂温克族",
    "德昂族", "保安族", "裕固族", "京族", "塔塔尔族",
    "独龙族", "伦春族", "赫哲族", "门巴族", "珞巴族",
    "基诺族"
]

// 日期选择器配置
const shortcuts = [
  {
    text: '今天',
    value: new Date(),
  },
  {
    text: '昨天',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() - 3600 * 1000 * 24)
      return date
    },
  },
]

const disabledDate = (time) => {
  return time.getTime() > Date.now()
}

// 计算年龄
const calculateAge = () => {
  console.log(form.value)
  if (!form.value.birthDate) return
  
  const birthDate = new Date(form.value.birthDate)
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  
  form.value.age = age
}

// 计算 BMI
const calculateBMI = () => {
  if (form.value.height && form.value.weight) {
    const heightInMeters = form.value.height / 100
    form.value.bmi = (form.value.weight / (heightInMeters * heightInMeters)).toFixed(1)
  }
}

// 自定义字段相关方法
const addCustomField = () => {
  customFields.value.push({
    label: '',
    value: {
      value: '',
      unit: ''
    },
    isEditing: true,
    tempLabel: '',
    error: ''
  })
}

const editFieldLabel = (field) => {
  field.tempLabel = field.label
  field.isEditing = true
  field.error = ''
}

const onFieldLabelBlur = (field, index) => {
  if (!field.tempLabel.trim()) {
    customFields.value.splice(index, 1)
  }
}

const onFieldLabelConfirm = (field, index) => {
  field.error = ''
  
  const label = field.tempLabel.trim()
  if (!label) {
    field.error = '请输入字段名称'
    return
  }
  
  if (!field.value.value.trim()) {
    field.error = '请输入字段值'
    return
  }
  
  const isDuplicate = customFields.value.some((f, i) => 
    i !== index && 
    f.label && 
    !f.isEditing && 
    f.label.toLowerCase() === label.toLowerCase()
  )
  
  if (isDuplicate) {
    field.error = '字段名称已存在'
    return
  }
  
  field.label = label
  field.isEditing = false
  if (!field.value || typeof field.value !== 'object') {
    field.value = {
      value: '',
      unit: ''
    }
  }
}

const removeCustomField = (index) => {
  customFields.value.splice(index, 1)
}
watch([() => form.value.height, () => form.value.weight], ([newHeight, newWeight]) => {
  calculateBMI(newHeight, newWeight);
});
// 监听 props.customFields 的变化
watch(() => props.customFields, (newFields) => {
  customFields.value = newFields.map(field => ({
    ...field,
    isEditing: false,
    tempLabel: '',
    error: ''
  }))
}, { immediate: true })

// 监听 patientInfo 变化
watch(() => props.patientInfo, (newVal) => {
  console.log(newVal)
  if (newVal) {
    // 处理基本信息
    form.value = {
      name: newVal?.name || '',
      gender: newVal?.gender || '',
      ethnicity: newVal?.ethnicity || '',
      birthDate: newVal?.birthDate || '',
      age: newVal?.age || '',
      height: newVal?.height || null,
      weight: newVal?.weight || null,
      bmi: newVal?.bmi || '',
    }
    
    // 处理自定义字段
    if (newVal.customFields) {
      customFields.value = Object.entries(newVal.customFields).map(([label, data]) => ({
        label,
        value: {
          value: data.value || '',
          unit: data.unit || ''
        },
        isEditing: false,
        tempLabel: '',
        error: ''
      }))
    }
  }
}, { immediate: true })

const handleClose = () => {
  visible.value = false
}

const handleSave = () => {
  // 构建保存的数据结构
  const customData = {}
  customFields.value.forEach(field => {
    if (field.label && !field.isEditing) {
      customData[field.label] = {
        value: field.value.value,
        unit: field.value.unit
      }
    }
  })

  const saveData = {
    basicInfo: {
      name: form.value.name,
      gender: form.value.gender,
      ethnicity: form.value.ethnicity,
      birthDate: form.value.birthDate,
      age: form.value.age,
      height: form.value.height,
      weight: form.value.weight,
      bmi: form.value.bmi
    },
    customFields: customData
  }
  console.log(form.value)
  console.log(saveData)
  emit('save', saveData)
  visible.value = false
}
</script>
