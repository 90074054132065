import{ getColor, seconds2HHmm, formatDateString, ledColor } from '@/plugins/utils/utils';

import {locales}from '@/plugins/utils/locales';
import {
    chart_dates, // 图表横轴的日期与报告数据之间的映射关系
    getInterval
} from './variables.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

let ahi_chart   = null;
let ahi2345_chart   = null;
let ahi_data = null;
let ahi2345_data = null;

// var { 
//     chart_dates, //图表横轴的日期与报告数据之间的映射关系
//     getInterval,
// } = require('./variables.js');

function initAHIChart(canvas, width, height, dpr) {
    var theme = null;
    try{
        let info = wx.getAppBaseInfo();
        theme = info.theme || null;
    }catch(e) {}
    
    const chart = echarts.init(canvas, theme, {
            width: width,
            height: height,
            devicePixelRatio: dpr,
            renderer: 'svg',
        });
    canvas.setChart(chart);
    ahi_chart = chart;

    let timer = null;
    chart.getZr().handler.on('mouseup', function(e){
        timer = setTimeout(()=>{
            chart.dispatchAction({
                type: 'hideTip'
            })
            chart.dispatchAction({
                type: 'updateAxisPointer',
                currTrigger: 'leave',
            })
        }, 4000);
    })
    chart.getZr().handler.on('mousedown', function(e){
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    })

    ahi_data && setAHIChartOption(ahi_data);
    return chart;
}

function getSymbol(value, params, data) { //单点数据需要显示symbol，能连成线则不显示
    var left = params.dataIndex -1;
    var right = params.dataIndex +1;
    var symbol = 'none';
    if (left>=0 && right<data.length) { //中间单点
        if (data[left].value==null && data[right].value==null) {
            symbol = 'circle';
        }
    } else if (right<data.length) { //最左边缘
        if (data[right].value==null) symbol = 'circle';
    } else if (left>=0) { //最右边缘
        if (data[left].value==null) symbol = 'circle';
    }
    return symbol;
}

export function setAHIChartOption(reports, ahi_chart,chart_dates) {
    ahi_data = reports;
    if (!ahi_chart) {
        return;
    }

    var dataAHI = [];
    var dataXAxis = [];

    //从日期序列转换到图表数据
    var weekmode = false;
    if (Object.keys(reports).length > 0) {
        const total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total<=60) {
            for (var key in chart_dates) {
                let [year, month, day] = key.split('/');
                let abbrKey;
                if (day === undefined) {
                    abbrKey = year + '-' + month;
                } else {
                    abbrKey = month + '-' + day;
                }
                let ahi = reports[key] && reports[key].ahi != null
                ? reports[key].ahi.toFixed(1)
                : null;
                dataAHI.push({ name: abbrKey, value: ahi });
                dataXAxis.push( abbrKey );
            }
        } else {
            weekmode = true;
            let [ahi_avg, count] = [0, 0];
            let valid = false; // 跳过开头的“半周”
            let weekend = '';
            for (let key in chart_dates) {
                let [year, month, day] = key.split('/');
                let d = new Date();
                d.setHours(0, 0, 0, 0);
                d.setFullYear(year, month - 1, day);
                let weekday = d.getDay();

                let ahi = reports[key] && reports[key].ahi != null
                ? reports[key].ahi.toFixed(1)
                : null;
                if (ahi!=null && valid) {
                    ahi_avg += parseFloat(ahi); 
                    count++;
                }
                if (weekday===6) { //每周最后一天
                    weekend = month+'-'+day;
                }
                if (weekday===0) { //每周第一天
                    if (valid) {
                        ahi_avg = count ? (ahi_avg / count).toFixed(1) : null;

                        key = month+'-'+day + L.to + weekend;
                        dataAHI.push({ name: key, value: ahi_avg }); //HERE
                        dataXAxis.push( key );
                        [ahi_avg, count] = [0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
        }
    }
    dataAHI.reverse();
    dataXAxis.reverse();
    var option = {
        animationDuration: 500,
        backgroundColor: 'transparent',
        /*title: {
            text: 'AHI指数',
            padding: [15, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        },*/
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesDesc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            axisPointer: {
                type: 'line',
                label: { 
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
        
            valueFormatter: (value)=> {
                return value ? (Math.round(value*10)/10).toFixed(1) : '-';
            },},
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: { alignWithLabel: true },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : 1,
                formatter: (value)=>{
                    if (weekmode) {
                        let [weekstart, weekend] = value.split(L.to);
                        let [startMonth, startDay] = weekstart.split('-');
                        let [endMonth, endDay] = weekend.split('-');
                        if (startMonth!=endMonth || startDay==1) { // 月初
                            return monthNameArray[parseInt(endMonth) - 1];
                        } else { return '' }
                    } else { return value; }
                }
            },
            data: dataXAxis
        }],
        yAxis:{
            type: 'value',
            min: 0,
            minInterval: 5,
            axisLabel: {
                show: true,
                customValues: [0, 5, 15, 30],
            },
            axisTick: {
                alignWithLabel: true,
                customValues: [0, 5, 15, 30],
            }
        },
        visualMap: {
            show: false,
            dimension: 1,
            pieces: [
              { lt: 5, color: ledColor[0] },
              { gte: 5, lt: 15, color: ledColor[1] },
              { gte: 15, lt: 30, color: ledColor[2] },
              { gte: 30, color: ledColor[3] }
            ]
        },
        series: [ {
            name: 'AHI',
            type: 'line',
            itemStyle: { color: '#df6047' },
            symbol: 'circle',
            symbolSize: 5,
            smooth: true,
            data: dataAHI,
            markArea: {
                silent: true,
                label: {
                    position: 'insideTopLeft',
                    color: getColor('title'),
                    fontSize: 8,
                    distance: 2,
                },
                data: [
                    [ { name: L.normal, yAxis: 0, itemStyle: { color: ledColor[0], opacity: 0.2 }, }, { yAxis: 5 } ],
                    [ { name: L.osa_mild, yAxis: 5.1, itemStyle: { color: ledColor[1], opacity: 0.2 }, }, { yAxis: 15 } ],
                    [ { name: L.osa_moderate, yAxis: 15.1, itemStyle: { color: ledColor[2], opacity: 0.2 }, }, { yAxis: 30 } ],
                    [ { name: L.osa_severe, yAxis: 30.1, itemStyle: { color: ledColor[3], opacity: 0.2 }, }, { yAxis: 200 } ],
                ]
            }
        }]
    };
    ahi_chart.setOption(option);
    //console.log("AHI chart optionized with: " + reports.length + " reports");
}