import * as echarts from 'echarts';

import { getColor, seconds2HHmm, formatDateString } from '@/plugins/utils/utils';
const catColor = ['#36349d', '#3478f6', '#59aae0', '#fe8973', '#CCC', '#8E377A'];

const minSegmentWidth = 5;

export function renderItem(params, api) {
    var curveShape = {
        type: 'rect' //避免animation bug
    };
    var rectShape = {
        type: 'rect'
    };
    var markerShape = {
        type: 'rect'
    };

    var categoryIndex = api.value(0);
    var nextCategoryIndex = api.value(3);
    if (categoryIndex>=0) {
        var start = api.coord([api.value(1), (categoryIndex>3) ? 3 : categoryIndex]);
        var end = api.coord([api.value(2), (categoryIndex>3) ? 3 : categoryIndex]);
        var start2 = nextCategoryIndex!=-1 ? api.coord([api.value(1), (nextCategoryIndex>3) ? 3 : nextCategoryIndex]) : start;
        var end2 = nextCategoryIndex!=-1 ? api.coord([api.value(2), (nextCategoryIndex>3) ? 3 : nextCategoryIndex]) : end;
        var height = api.size([0, 1])[1] * 0.7;
        
        var cond1 = (start[1]>start2[1]);
        var cond2 = (start[1]>=start2[1]);
        var x1 = end[0];
        var y1 = start[1] + ( cond1 ? -height*1/3 : height*2/3);
        var x2 = end2[0];
        var y2 = start2[1] + ( cond2 ? height*2/3 : -height/3);

        var thisColor = catColor[categoryIndex];
        var nextColor = catColor[nextCategoryIndex];
    
        rectShape = {
                type: 'rect',
                x: start[0] - 0.5,              // offset半个像素才能与直线对齐，dont know why..
                y: start[1] - height / 3,
                width: end[0] - start[0] + 1,   //两倍半像素
                height: height
            };
        if (categoryIndex==4) { //卧床无意图
            rectShape = {
                type: 'rect',
                x: start[0],
                y: start[1] - height / 3,
                width: end[0] - start[0],
                height: height
            };
        }
        if (categoryIndex==5) { //离床
            //height = api.size([0, 1])[1];
            rectShape = {
                type: 'rect',
                x: start[0],
                y: start[1] - height /3 - ((rectShape.width<minSegmentWidth) ? height*0.1 : 0), //((rectShape.width<minSegmentWidth) ? height*0.8 : height*0.7) / ((rectShape.width<minSegmentWidth) ? 2 : 3),
                width: end[0] - start[0],
                height: (rectShape.width<minSegmentWidth) ? height*1.1 : height,
                z: 10,
            };
            
            if (rectShape.width<minSegmentWidth) {
                let mWidth = 7;
                let mHeight = 6;
                markerShape = {
                    type: 'polygon',
                    shape: {
                        points: [ [start[0] + rectShape.width/2 - mWidth/2, start[1] - rectShape.height/2 - mHeight/2], 
                        [start[0] + rectShape.width/2  + mWidth/2, start[1] - rectShape.height/2 - mHeight/2], 
                        [start[0] + rectShape.width/2 , start[1] - rectShape.height/2 + mHeight/2] ]
                    },
                    /*type: 'circle',
                    shape: {
                        cx: start[0] + rectShape.width/2,
                        cy: start[1] - rectShape.height/2,
                        r: mHeight / 2,
                    },*/
                    style: {
                        fill: thisColor,
                        opacity: 0.8,
                    },
                    emphasis: {
                        style: {
                            stroke: thisColor,
                            lineWidth: 3,
                        }
                    },
                    z: 20,
                }
            }
        }
        
        //下一分段有效，则画连接线
        if (!(categoryIndex>=3 && nextCategoryIndex>=3) && nextCategoryIndex>=0) { 
            curveShape = {
                type: 'line',
                silent: true,
                transition: ['shape'],
                shape: {
                    x1: x1,
                    y1: y1+(cond1?3:-3),
                    x2: x2,
                    y2: y2+(cond1?-3:3),
                },
                style: {
                    stroke: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0, color: cond1 ? nextColor : thisColor
                    }, {
                        offset: 1, color: cond1 ? thisColor : nextColor
                    }
                    ]),
                    lineWidth: 1,
                    opacity: 0.7
                }
            };
        }
    }

    let radius = 4;//(rectShape.width > 2) ? 4 : 0;
    return ( {
        type: 'group',
        children: [
            curveShape,
            markerShape,
            rectShape && {
                type: 'rect',
                silent: false,//(categoryIndex>3),
                transition: ['shape'],
                shape: {...rectShape, r: radius },
                style: {...api.style(), 
                    fill: thisColor,
                    opacity: (categoryIndex>3) ? 0.8 : 1,
                },
                //z: 2,
                emphasis: {
                    style: {
                        stroke: thisColor,
                        lineWidth: 3,
                        //shadowBlur: 2,
                    }
                },
            }
        ]
    } );
}

// 睡眠分期合并：5分期合并为4分期
function mergeSleepStage(stage) {
    switch(stage) {
        case -1: return -1; // 无效的数据段
        case 1: return 3;   // WAKE
        case 2: return 2;   // REM
        case 3: return 1;   // Core
        case 4: return 1;   // Core
        case 5: return 0;   // Deep
        default:
            return 3;
    }
}
export function setStageChartOption (stage_chart, stage_data) {
  if (!stage_data || !stage_data.stages) {
    console.log('Empty stage data, set chart fail.')
    return;
  }
  if (!stage_chart) { // 图表组件尚未初始化
    return;
  }

  const data = [];
  const categories = ['深睡眠', '浅睡眠', '快速眼动', '清醒'];

  const baseTime = stage_data.start;
  // 从睡眠分期数组读出绘图数据
  for (let i = 0; i < stage_data.stages.length; i++) {
    // 5分期合并为4分期
    const stg = mergeSleepStage(stage_data.stages[i].value);

    data.push({
      name: stg >= 0 ? categories[stg] : '',
      value: [stg, baseTime * 1000 + stage_data.stages[i].start * 1000, baseTime * 1000 + stage_data.stages[i].end * 1000, -1],
      itemStyle: { color: stg >= 0 ? catColor[stg] : '#FFF' }
    });
  }

  // 分期中的相邻碎片合并
  for (let i = 1; i < data.length; i++) {
    if (data[i - 1].value[0] === data[i].value[0]) {
      data[i - 1].value[2] = data[i].value[2];
      data.splice(i, 1);
      i--;
    }
  }
  // 对离床和非意图段也做一遍碎片合并检查
  for (let i = 0; i < stage_data.nointention?.length; i++) {
    stage_data.nointention[i].s = Math.round(stage_data.nointention[i].s);
    stage_data.nointention[i].e = Math.round(stage_data.nointention[i].e);
  }
  for (let i = 1; i < stage_data.nointention?.length; i++) {
    if ((stage_data.nointention[i - 1].e === stage_data.nointention[i].s) || (stage_data.nointention[i - 1].e + 1 === stage_data.nointention[i].s)) {
      stage_data.nointention[i - 1].e = stage_data.nointention[i].e;
      stage_data.nointention.splice(i, 1);
      i--;
    }
  }
  for (let i = 1; i < stage_data.leavebed?.length; i++) {
    if ((stage_data.leavebed[i - 1].e === stage_data.leavebed[i].s) || ((stage_data.leavebed[i - 1].e + 1 === stage_data.leavebed[i].s))) {
      stage_data.leavebed[i - 1].e = stage_data.leavebed[i].e;
      stage_data.leavebed.splice(i, 1);
      i--;
    }
  }

  // 用离床和非意图时间把wake分期拆开
  let hasIntentionData = false;
  let hasLeaveData = false;
  for (let i = 0; i < data.length; i++) {
    if (data[i].value[0] === 3) {
      for (let j = 0; j < stage_data.nointention?.length; j++) {
        const nointent = stage_data.nointention[j];
        const oldname = data[i].name;
        const oldstart = data[i].value[1] / 1000;
        const oldend = data[i].value[2] / 1000;
        const oldstyle = data[i].itemStyle;
        if (baseTime + nointent.s >= oldstart && baseTime + nointent.e <= oldend) { // 如果当前wake分期与意图有重叠
          hasIntentionData = true;
          let idx = i;
          if (baseTime + nointent.s > oldstart) { // 如果有，拆分第一个wake段，并替换掉原来的wake段
            data.splice(i, 1, {
              name: oldname,
              value: [3, oldstart * 1000, baseTime * 1000 + nointent.s * 1000, -1],
              itemStyle: oldstyle
            })
            idx = i + 1;
          } else { // 否则要删掉原来的wake段
            data.splice(i, 1);
          }
          // 插入一个非意图段
          data.splice(idx, 0, {
            name: '活动',
            value: [4, baseTime * 1000 + nointent.s * 1000, baseTime * 1000 + nointent.e * 1000, -1],
            itemStyle: { color: catColor[4] }
          })
          if (baseTime + nointent.e < oldend) { // 如果有，拆分后一个wake段
            data.splice(idx + 1, 0, {
              name: oldname,
              value: [3, baseTime * 1000 + nointent.e * 1000, oldend * 1000, -1],
              itemStyle: oldstyle
            })
          }
        }
      }
    }
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].value[0] === 3) {
      for (let j = 0; j < stage_data.leavebed?.length; j++) {
        const leave = stage_data.leavebed[j];
        const oldname = data[i].name;
        const oldstart = data[i].value[1] / 1000;
        const oldend = data[i].value[2] / 1000;
        const oldstyle = data[i].itemStyle;
        if (baseTime + leave.s >= oldstart && baseTime + leave.e <= oldend) { // 如果当前wake分期与意图有重叠
          hasLeaveData = true;
          let idx = i;
          if (baseTime + leave.s > oldstart) { // 如果有，拆分第一个wake段，并替换掉原来的wake段
            data.splice(i, 1, {
              name: oldname,
              value: [3, oldstart * 1000, baseTime * 1000 + leave.s * 1000, -1],
              itemStyle: oldstyle
            })
            idx = i + 1;
          } else { // 否则要删掉原来的wake段
            data.splice(i, 1);
          }
          // 插入一个离床段
          data.splice(idx, 0, {
            name: '离床',
            value: [5, baseTime * 1000 + leave.s * 1000, baseTime * 1000 + leave.e * 1000, -1],
            itemStyle: { color: catColor[5] }
          })
          if (baseTime + leave.e < oldend) { // 如果有，拆分后一个wake段
            data.splice(idx + 1, 0, {
              name: oldname,
              value: [3, baseTime * 1000 + leave.e * 1000, oldend * 1000, -1],
              itemStyle: oldstyle
            })
          }
        }
      }
    }
  }

  // 在每一段的数据中记住下一个段的分期值
  let firstLegend = null; // 记住第一个出现的需要legend的类型，画legend按出现的顺序来排
  for (let i = 0; i < data.length - 1; i++) {
    if (data[i].value[0] === 4 && !firstLegend) {
      firstLegend = 'intent';
    }
    if (data[i].value[0] == 5 && !firstLegend) {
      firstLegend = 'leave';
    }
    if (data[i + 1].value[1] == data[i].value[2]) // 处理空洞，空洞位置不画连接线
    { data[i].value[3] = data[i + 1].value[0]; } else { data[i].value[3] = -1; }
  }
  // console.log(data);

  const sleepStart = stage_data.start * 1000;
  const sleepEnd = stage_data.end * 1000;

  const option = {
    backgroundColor: 'transparent',
    tooltip: {
      triggerOn: 'click',
      backgroundColor: getColor('background'),
      borderColor: 'transparent',
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      formatter: function (params) {
        const len = (params.value[2] - params.value[1]) / 1000;
        return params.marker +
                    params.name + ': ' + seconds2HHmm(len, true) +
                    '\n' + formatDateString(params.value[1], 'HHmm') + '开始' +
                    ' - ' + formatDateString(params.value[2], 'HHmm') + '结束';
      },
      // valueFormatter: (value) => formatDateString(value, "HHmm"),
      confine: true,
      shadowBlur: 4
    },
    grid: {
      left: '8%',
      right: '8%',
      top: '20%',  // 增加顶部空间
      bottom: '2%',
      containLabel: true
    },
    legend: {
      data: firstLegend === 'leave' ? [hasLeaveData ? '离床' : '', hasIntentionData ? '活动' : ''] : [hasIntentionData ? '活动' : '', hasLeaveData ? '离床' : ''],
      orient: 'horizontal',
      top: '5%',   // 将图例向上移动
      selectedMode: false,
      itemHeight: 12,
      itemWidth: 8,
      itemGap: 6,
      itemStyle: {
        opacity: 0.9
      },
      textStyle: {
        fontSize: 10
      }
    },
    
    xAxis: {
      min: 'dataMin',
      max: 'dataMax',
      minInterval: 120 * 60000,
      maxInterval: 120 * 60000,
      splitLine: {
        lineStyle: { type: 'dashed' }
      },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        formatter: function (val) {
          const d = new Date(val);
          const tag = d.getHours() + ':' + d.getMinutes().toString().padStart(2, '0');
          // if (val % 3600000 != 0)
          if (val === sleepStart || val === sleepEnd) {
            return '{endtag|' + tag + '}';
          } else { return '{all|' + tag + '}'; }
        },
        rich: {
          all: {
            padding: 2
          },
          endtag: {
            color: getColor('text'),
            backgroundColor: getColor('tag'),
            padding: 2,
            borderRadius: 2
          }
        }
      }
    },
    yAxis: {
      data: categories,
      axisTick: {
        show: true,
        inside: false
      },
      axisLabel: {
        inside: true,
        fontSize: 9,
        padding: [-14, 0, 0, -5],
        verticalAlign: 'top'
      },
      z: 10
    },
    dataZoom: {
      type: 'inside',
      start: 0,
      end: 100,
      filterMode: 'weakFilter', // empty会exception
      minSpan: 40,
      maxSpan: 100,
      zoomLock: false,
      orient: 'horizontal'
    },
    series: [{
      type: 'custom',
      clip: false,
      renderItem,
      itemStyle: {
        borderWidth: 0,
        opacity: 0.9,
        borderColor: '#dddddd'
      },
      dimensions: ['分期', '开始', '结束'],
      encode: {
        x: [1, 2],
        y: 0,
        tooltip: [1, 2]
      },
      data
    }, {
      type: 'custom',
      renderItem,

      name: '离床',
      itemStyle: {
        color: catColor[5]
      }
    }, {
      type: 'custom',
      renderItem,

      name: '活动',
      itemStyle: {
        color: catColor[4]
      }
    }]
  };
  stage_chart.setOption(option);
}
