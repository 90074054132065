<!-- Sleep Analysis Component -->
<template>
  <div class="sleep-analysis">
    <van-loading v-if="loading" vertical>加载中...</van-loading>
    
    <template v-else>
      <!-- Sleep Overview -->
      <div class="analysis-module">
        <div class="module-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/Bed Solid.svg" alt="Bed Icon">
            <span class="module-title">睡眠分析</span>
          </div>
          <span class="info-icon" @click="(e) => showInfoModal('sleep', e)">ⓘ</span>
        </div>
        <div class="stats-grid">
          <div class="stat-item">
            <div class="stat-label">总睡眠时长</div>
            <div class="stat-value">{{ sleepData.totalDuration }}</div>
          </div>
          <div class="stat-item">
            <div class="stat-label">入睡速度</div>
            <div class="stat-value">{{ sleepData.fallAsleepTime }}</div>
          </div>
          <!-- <div class="stat-item">
            <div class="stat-label">睡眠效率</div>
            <div class="stat-value">{{ sleepData.efficiency }}</div>
          </div> -->
        </div>
        
        <!-- Sleep Stage Chart (Placeholder) -->
        <div class="content-card">
          <div>
            <div class="stage-chart" :id="chartId" style="width: 100%; height: 120px; background-color: white; margin-top: 5px;margin-bottom: 10px"></div>
            <div class="art-chart" :id="ArtChartId" style="width: 100%; height: 70px; background-color: white; margin-top: 10px;margin-bottom: 5px"></div>
          </div>
  
          <div class="sleep-types">
            <div class="type-item">
              <span class="type-dot wake"></span>
              <span class="type-label">清醒</span>
              <span class="type-value">{{ sleepData.wakeTime }}</span>
            </div>
            <div class="divider"></div>
            <div class="type-item">
              <span class="type-dot rem"></span>
              <span class="type-label">快速眼动睡眠</span>
              <span class="type-value">{{ sleepData.remTime }}</span>
            </div>
            <div class="divider"></div>
            <div class="type-item">
              <span class="type-dot light"></span>
              <span class="type-label">浅睡眠</span>
              <span class="type-value">{{ sleepData.lightTime }}</span>
            </div>
            <div class="divider"></div>
            <div class="type-item">
              <span class="type-dot deep"></span>
              <span class="type-label">深睡眠</span>
              <span class="type-value">{{ sleepData.deepTime }}</span>
            </div>
          </div>
        </div>
        

      </div>

      <!-- HRV Section -->
      <div class="analysis-module">
        <div class="module-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/Heart Pulse Icon.svg" alt="Heart Icon">
            <span class="module-title">心率变异性 (HRV)</span>
          </div>
          <span class="info-icon" @click="(e) => showInfoModal('hrv', e)">ⓘ</span>
        </div>
        <div class="content-card">
          <div class="card-subtitle">睡眠平均心率变异性</div>
              <!-- 数据卡片 -->
              <div class="data-card">
                <div class="main-value">{{ hrvData.value }}毫秒</div>
              </div>
              <div class="value-note" v-if="hrvData.note">{{ hrvData.note }}</div>

              <!-- 数据范围标记 -->
              <DataMarker
              :value="hrvStat.value"
              :low="hrvStat.low"
              :high="hrvStat.high"
              :label-low="hrvStat.labelLow"
              :label-high="hrvStat.labelHigh"
            />
    
          <!-- <div class="main-value">{{ hrvData.value }}毫秒</div> -->
          <div class="hrv-chart" :id="HrvChartId" style="width: 100%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        </div>
      </div>

      <!-- Breathing Health -->
      <div class="analysis-module">
        <div class="module-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/Lungs Solid.svg" alt="Lungs Icon">
            <span class="module-title">呼吸健康</span>
          </div>
          <span class="info-icon" @click="(e) => showInfoModal('breathing', e)">ⓘ</span>
        </div>
        <div class="content-card">
          <div class="card-subtitle">呼吸暂停低通气指数（AHI）</div>
          <div class="main-value">{{ breathingData.ahiValue }}</div>
          <div class="value-note" v-if="breathingData.note">
            <span :style="{ color: breathingData.noteColor }">{{ breathingData.riskText }}</span>
            <div>{{ breathingData.suggestion }}</div>
          </div>
          <div class="breathing-chart" :id="AHiChartId" style="width: 100%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        </div>
      </div>

      <!-- Sleep Environment -->
      <div class="analysis-module">
        <div class="module-header">
          <div class="header-left">
            <img class="module-icon" src="@/assets/House Chimney Icon.svg" alt="Home Icon">
            <span class="module-title">睡眠环境</span>
          </div>
          <span class="info-icon" @click="(e) => showInfoModal('environment', e)">ⓘ</span>
        </div>
        <div class="env-stats-grid">
          <div class="stat-item">
            <div class="stat-label">温度</div>
            <div class="stat-value-row">
              <span>{{ environmentData.temperature }}°C</span>
              <span class="stat-status" :style="{ color: environmentData.temperatureColor }">
                {{ environmentData.temperatureStatus }}
              </span>
            </div>
          </div>
          <div class="stat-item">
            <div class="stat-label">湿度</div>
            <div class="stat-value-row">
              <span>{{ environmentData.humidity }}%</span>
              <span class="stat-status" :style="{ color: environmentData.humidityColor }">
                {{ environmentData.humidityStatus }}
              </span>
            </div>
          </div>
          <div class="stat-item">
            <div class="stat-label">光照</div>
            <div class="stat-value-row">
              <span>{{ environmentData.light }}lx</span>
              <span class="stat-status" :style="{ color: environmentData.lightColor }">
                {{ environmentData.lightStatus }}
              </span>
            </div>
          </div>
        </div>
        <div class="content-card">
          <div class="environment-chart" :id="EnvChartId" style="width: 100%; height: 150px; background-color: white; margin-top: 5px;margin-bottom: 5px"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { showToast } from 'vant'
import * as echarts from 'echarts'
import BedIcon from '@/assets/Bed Solid.svg'
import HeartIcon from '@/assets/Heart Pulse Icon.svg'
import LungsIcon from '@/assets/Lungs Solid.svg'
import HomeIcon from '@/assets/Ionicons Home Icon.svg'

import {createAxiosInstance, wdb_get_history_new,request_data} from "@/plugins/axios.js";
import {setStageChartOption} from "@/plugins/chart/stage_chart";
import {setArt2ChartOption } from "@/plugins/chart/day_art_chart2";
// import { setEnvChartOption } from '@/plugins/chart/env_chart';
import {chart_dates, resetDates} from "@/plugins/chart/variables";
// import {setHRVChartOption} from "@/plugins/chart/hrv_chart";
import {setHRVChartOption} from "@/plugins/chart/hr_chart_v2";
// import {setAHIChartOption} from "@/plugins/chart/ahi_month_chart";
import{setAHIChartOption} from "@/plugins/chart/ahi_trend_chart"

import {formatDateString} from "@/plugins/utils/utils";
import{setHRVBaselineChartOption} from "@/plugins/chart/hrv_baseline_chart";
// import { setDayEnvChartOption } from '@/plugins/chart/day_env_chart'
import { setDayEnvChartOption } from '@/plugins/chart/env_chart_v2'
import DataMarker from './DataMarker.vue'
const chartData = ref({})
const reports_data = ref([])

const chart = ref(null)
const art_chart = ref(null)
const env_chart = ref(null)
const ahi_chart = ref(null)
// Props
const props = defineProps({
  deviceId: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  }
})

// Data
const loading = ref(false)
const error = ref(null)

const sleepData = reactive({
  totalDuration: '--',
  fallAsleepTime: '--',
  efficiency: '--',
  wakeTime: '--',
  remTime: '--',
  lightTime: '--',
  deepTime: '--'
})

const hrvData = reactive({
  value: 0,
  note: ''
})

const breathingData = reactive({
  ahiValue: 0,
  note: '',
  riskText: '',
  suggestion: '',
  noteColor: ''
})

const environmentData = reactive({
  light: 0,
  lightStatus: '--',
  lightColor: '',
  temperature: 0,
  temperatureStatus: '--',
  temperatureColor: '',
  humidity: 0,
  humidityStatus: '--',
  humidityColor: ''
})

// 添加一个状态来跟踪所有图表是否都渲染完成
const chartsLoaded = ref({
  stageChart: false,
  artChart: false,
  envChart: false,
  hrvChart: false,
  ahiChart: false
})

function formatMinutes(minutes) {
  if (Number.isFinite(minutes)) {  // 检查minutes是否为一个有限的数
    const roundedMinutes = Math.round(minutes);
    const hours = Math.floor(roundedMinutes / 60);
    const remainingMinutes = roundedMinutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} 分钟`;
    }

    return `${hours} 时 ${remainingMinutes} 分`;
  }
  return '';
}
const getSleepEfficiency = (tst, tib) => {
  if (!tst || !tib) return '--'
  return ((tst / tib) * 100).toFixed(0)
}
// Methods
const fetchSleepData = async () => {
  try {
    loading.value = true
    // 重置图表加载状态
    Object.keys(chartsLoaded.value).forEach(key => {
      chartsLoaded.value[key] = false
    })

    const axiosInstance = createAxiosInstance('数据服务器')
    const response = await axiosInstance.get(`devicereport/${props.deviceId}/${props.date}/`)
    await getReportList(props.date)

    // 检查数据是否存在
    const hasData = response?.data?.data?.online?.tst !== undefined

    // 发送初始高度和数据状态
    const initialHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    console.log('initialHeight',initialHeight)
    console.log('hasData',hasData)
    window.parent.postMessage({ 
      type: 'setHeight', 
      height: initialHeight,
      status: 'loading',
      hasData
    }, '*')

    if (hasData) {
      const data = response.data.data
      chartData.value = data
      
      // 更新所有数据
      await updateAllData(data)
      
      // 等待 DOM 更新后再初始化图表
      await nextTick()
      // 确保 DOM 完全渲染后再初始化图表
      setTimeout(async () => {
        try {
          await Promise.all([
            doStageChart(),
            doArtChart(),
            doEnvChart(),
            doHrvBlChart(),
            doAhiChart()
          ])
        } catch (error) {
          console.error('Error initializing charts:', error)
          checkAllChartsLoaded()
        }
      }, 100)
    } else {
      // 如果没有数据，直接发送最终高度
      const finalHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      window.parent.postMessage({ 
        type: 'setHeight', 
        height: finalHeight,
        status: 'complete',
        hasData: false
      }, '*')
    }
  } catch (error) {
    console.error('Failed to fetch sleep data:', error)
    showToast('获取睡眠数据失败')
    // 发送错误状态和页面高度
    const errorHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    window.parent.postMessage({ 
      type: 'setHeight', 
      height: errorHeight,
      status: 'error',
      hasData: false
    }, '*')
  } finally {
    loading.value = false
  }
}

// 添加新的函数来更新所有数据
const updateAllData = async (data) => {
  // Update sleep data
  console.log('data.online',data.online)
  Object.assign(sleepData, {
    totalDuration: formatMinutes(data.online.tst) || '--',
    fallAsleepTime: formatMinutes(data.online.sol)|| '--',
    efficiency: getSleepEfficiency(data.online.tst, data.online.tib)+'%' || '--' || '--',
    wakeTime: formatMinutes(data.online.sleep_dur_1) || '--',
    remTime: formatMinutes(data.online.sleep_dur_2)|| '--',
    lightTime: formatMinutes(data.online.core_time) || '--',
    deepTime: formatMinutes(data.online.deep_time)|| '--'
  })

  // Update HRV data
  Object.assign(hrvData, {
    value: data.summary.avg_hrv_rmssd || 0,
    note: getHrvNote(reports_data.value) || data.hrv?.note || ''
  })

  // Update breathing data
  const ahiNote = data.online.valid_avg_ahi ? getAHINote(data.online.ahi) : { text: '', riskText: '', suggestion: '', color: '' }
  Object.assign(breathingData, {
    ahiValue: data.online.ahi?.toFixed(1) || 0,
    note: ahiNote.text,
    riskText: ahiNote.riskText,
    suggestion: ahiNote.suggestion,
    noteColor: ahiNote.color
  })

  // Update environment data
  const avg_temp = parseFloat(data.online.avg_temp).toFixed(1)
  const avg_humi = Math.round(parseFloat(data.online.avg_humi))
  const avg_illu = Math.round(parseFloat(data.online.avg_illu))

  const illuStatus = getEnvironmentStatus('illu', avg_illu)
  const tempStatus = getEnvironmentStatus('temp', avg_temp)
  const humiStatus = getEnvironmentStatus('humi', avg_humi)

  Object.assign(environmentData, {
    light: avg_illu || 0,
    lightStatus: illuStatus.msg,
    lightColor: illuStatus.color,
    temperature: avg_temp || 0,
    temperatureStatus: tempStatus.msg,
    temperatureColor: tempStatus.color,
    humidity: avg_humi || 0,
    humidityStatus: humiStatus.msg,
    humidityColor: humiStatus.color
  })
}

// Lifecycle
onMounted(async () => {
  await fetchSleepData()
  window.addEventListener('resize', sendPageHeight)
  // 初始化时发送一次高度
  sendPageHeight()
})

// Modal
const showInfoModal = (type, event) => {
  // 获取点击的图标元素位置
  const iconElement = event.target
  const rect = iconElement.getBoundingClientRect()
  
  // 准备弹窗内容
  let content = ''
  let title = ''
  
  switch(type) {
    case 'sleep':
      title = '睡眠结构'
      content = `这张图展示了在睡眠中你所经历的不同周期。

当你放松地躺在床上，身体会逐渐从清醒状态慢慢滑入浅睡眠状态，在这个阶段心跳和呼吸逐渐减慢，体温开始降低。随后大脑活动继续减少，呼吸和心率也降至最低水平，进入深睡眠状态，此时我们很难被唤醒。

深睡眠后，则会进入「快速眼动睡眠」，顾名思义，这个阶段伴随着眼球的快速运动，大脑开始活跃起来，丰富奇幻的梦境也主要发生在这个阶段。在此期间，除了控制呼吸、心率和眼球运动的肌肉外，身体的大部分肌肉则处于麻痹状态。

每个「浅睡眠-深睡眠-快速眼动睡眠」的周期约持续90分钟，我们每晚都会经历好几轮这样的周期。不过，不同阶段的睡眠状态并非在每个周期均匀分布，入睡后的前半夜，深睡眠占比更多，后半夜，快速眼动睡眠的比例则大大增加。睡眠周期受到很多因素的影响，会因年龄、身体状况、运动强度，前一晚睡眠情况等因素而动态变化。

如果你因为某几晚深睡眠或快速眼动睡眠占比较少而感到焦虑，想要获得更加均衡、高质量的睡眠。其实方法你早就已经了解了，那些广为人知、有益睡眠和整体健康的生活方式，包括留出充足的睡眠时间，规律作息，戒烟忌酒，适量运动等，也都有益于获得足量的深睡眠，此外也并无他法。

当你努力建立更健康的生活习惯，也就是在帮助自己获得足量的深睡眠和快速眼动睡眠了。`
      break
    case 'hrv':
      title = '睡眠心率变异性'
      content = `心率变异性（HRV）测量心跳间隔波动，反映心脏自主神经系统功能。HRV是心跳间隔波动的主要指标，通常高值代表更好的心血管功能和抗压能力，低值则与抑郁、焦虑及心血管疾病风险增加相关。事实上，HRV的高低是相对的，取决于许多个人因素（如年龄、性别、昼夜节律、压力反应模式等），没有适用于所有人的标准。因此更有意义的是关注HRV是否保持在个人历史基线范围中，持续超出或低于个人的历史基线都可能暗示身体对压力的适应性或健康状况发生了变化。其中低于历史基线的情况更加需要引起关注，但短期内高出历史基线过多也可能反映异常的健康状况。

• HRV值在个人基线范围内的上升趋势可能表明个体的自我调节能力在增强，可能因为合理的饮食、锻炼或优质的睡眠，身体能够更好地适应压力或恢复到了更健康的状态。

• HRV值在个人基线范围内的下降趋势可能表明个体的自我调节能力受到了某些因素的影响，如压力过大、情绪波动、睡眠质量差等；吸烟、饮酒或某些药物也可能会造成HRV降低。

* HRV个人基线区间范围取自过去2个月积累的数据，使用2周后开始生成，请保持连续使用睡眠监测垫。`
      break
    case 'breathing':
      title = '呼吸暂停低通气指数 (AHI)'
      content = `阻塞性睡眠呼吸暂停（OSA）是一种以睡眠打鼾伴呼吸暂停和日间思睡为主要临床表现的睡眠呼吸疾病，患病率为2%～4%。该病可引起间歇性低氧、高碳酸血症以及睡眠结构紊乱，并可导致高血压、冠心病、心律失常、脑血管病、认知功能障碍、2型糖尿病等多器官多系统损害。研究表明，未经治疗的重度OSA患者病死率比普通人群高3.8倍。

OSA的诊断通常参考呼吸暂停-低通气指数（AHI）数值。AHI表示睡眠中每小时发生的呼吸暂停和低通气的平均次数，通过呼吸暂停和低通气事件（持续10秒以上）的总数除以睡眠的总时间来计算。成人的AHI数值分为三类，分别对应不同的OSA严重程度：
轻度：5<=AHI<15
中度：15<=AHI<30
重度：AHI>=30

阻塞性睡眠呼吸暂停通过过去30天内的数据综合评估（需要不少于14天的有效数据），请保持连续使用睡眠监测垫。

以上内容参考自：中国医师协会睡眠医学专业委员会。成人阻塞性睡眠呼吸暂停多学科诊疗指南。中华医学杂志，2018，98(24):1902-1914。`
      break
    case 'environment':
      title = '睡眠环境'
      content = `良好的睡眠环境能够帮助你获得更高质量的睡眠。通常来说，在15-23摄氏度，40%-60%的湿度，以及在照度小于1lx的黑暗环境中入睡能够帮助你获得更优质的睡眠，如果愿意，你可以参照图表改善自身睡眠环境，如加湿空气，调整空调温度等。

当然，如果你有自己习惯的睡眠环境，并未感到睡眠受到影响，也可以选择保持现状。`
      break
  }
  
  // 发送弹窗信息给父页面
  window.parent.postMessage({ 
    type: 'showModal',
    data: {
      title,
      content,
    }
  }, '*')
}
const chartId = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const ArtChartId = ref(`art_chart_${Math.random().toString(36).substr(2, 9)}`);
const EnvChartId = ref(`env_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrvChartId = ref(`hrv_chart_${Math.random().toString(36).substr(2, 9)}`);
const AHiChartId = ref(`ahi_chart_${Math.random().toString(36).substr(2, 9)}`);

// 修改图表初始化函数
const doStageChart = async () => {
  try {
    const canvas = document.getElementById(chartId.value)
    if (!canvas) {
      console.error('Chart container not found')
      return
    }

    // 如果已经存在图表实例，先销毁它
    if (chart.value) {
      chart.value.dispose()
    }

    // 准备数据
    const stage_data = {
      start: chartData.value.online.starttime,
      end: chartData.value.online.endtime,
      stages: chartData.value.online.stage_details,
      nointention: chartData.value.online.intention_middle?.length > 0 
        ? chartData.value.online.intention_middle 
        : null,
      leavebed: chartData.value.online.leavebed?.length > 0 
        ? chartData.value.online.leavebed 
        : null
    }

    // 初始化图表
    chart.value = echarts.init(canvas)
    setStageChartOption(chart.value, stage_data)
    
    // 标记该图表加载完成
    chartsLoaded.value.stageChart = true
    checkAllChartsLoaded()
  } catch (error) {
    console.error('Failed to initialize stage chart:', error)
    chartsLoaded.value.stageChart = true // 即使失败也标记完成
    checkAllChartsLoaded()
  }
}


// 修改图表初始化函数
const doEnvChart = async () => {
  try {
    const canvas = document.getElementById(EnvChartId.value)
    if (!canvas) {
      console.error('Chart container not found')
      return
    }

    // 如果已经存在图表实例，先销毁它
    if (env_chart.value) {
      env_chart.value.dispose()
    }

    // 准备数据
    const stage_data = {
      start: chartData.value.online.starttime,
      end: chartData.value.online.endtime,
      segments : chartData.value.online.thi_series,
      nointention :chartData.value.online.intention_middle,
      leavebed :chartData.value.online.leavebed,

    }
    // 初始化图表
    env_chart.value = echarts.init(canvas)
    setDayEnvChartOption(env_chart.value,stage_data)

    // 标记该图表加载完成
    chartsLoaded.value.envChart = true
    checkAllChartsLoaded()
  } catch (error) {
    console.error('Failed to initialize env chart:', error)
    chartsLoaded.value.envChart = true
    checkAllChartsLoaded()
  }
}

// 修改图表初始化函数
const doArtChart = async () => {
  try {
    const canvas = document.getElementById(ArtChartId.value)
    if (!canvas) {
      console.error('Chart container not found')
      return
    }

    // 如果已经存在图表实例，先销毁它
    if (art_chart.value) {
      art_chart.value.dispose()
    }

    // 准备数据
    const stage_data = {
      start: chartData.value.online.starttime,
      end: chartData.value.online.endtime,
      stages: chartData.value.online.stage_details,
      segments :chartData.value.online.artifact_series,
      nointention: chartData.value.online.intention_middle?.length > 0 
        ? chartData.value.online.intention_middle 
        : null,
      leavebed: chartData.value.online.leavebed?.length > 0 
        ? chartData.value.online.leavebed 
        : null
    }

    // 初始化图表
    art_chart.value = echarts.init(canvas)
    setArt2ChartOption(art_chart.value, stage_data)
    
    // 标记该图表加载完成
    chartsLoaded.value.artChart = true
    checkAllChartsLoaded()
  } catch (error) {
    console.error('Failed to initialize art chart:', error)
    chartsLoaded.value.artChart = true
    checkAllChartsLoaded()
  }
}

async function getReportList(reportdate) {
  try {
    // 确保 reportdate 是有效的日期字符串或时间戳
    if (!reportdate) {
      return;
    }

    // 将 reportdate 转换为 Date 对象并验证
    let selectedDate = new Date(reportdate);
    if (isNaN(selectedDate.getTime())) {
      console.error('Invalid report date:', reportdate);
      return;
    }

    // 计算起始日期和结束日期
    let endDate = new Date(selectedDate);
    let startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - 29);

    // 验证期范围
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date range');
      return;
    }

    // 格式化日期为 yyyy-mm-dd
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    let formattedStartDate = formatDate(startDate);
    let formattedEndDate = formatDate(endDate);

    // 验证 props.deviceId
    if (!props.deviceId) {
      console.error('Selected device is required');
      return;
    }

    // 发送请求
    let response = await request_data(
        `/deviceallreport/${props.deviceId}/${formattedStartDate}/${formattedEndDate}/`
    );
    console.log('response',response)
    // 验证响应数
    if (response) {
      reports_data.value = response;
      calculateHrvStat(reports_data.value)
    } else {
      console.error('No response data received');
    }
  } catch (error) {
    console.error('Error in getReportList:', error);
    // 这里可以添加错误处理逻辑，比如显示错误消息给用户
  }
}
const parseReportsToDisplay = (reports) => {
  //console.log("History:parseReportsToDisplay");

  // 传入了报告列表，是长时间趋势图模式
  var i;
  if (reports && reports.length > 0) {
    this.setData({
      reportCount: reports ? reports.length : 0,
    });
    let max_chart_days = 0
    if (this.data.period === 'week') {
      max_chart_days = 7;
    } else if (this.data.period === 'month') {
      max_chart_days = 30;
    } else if (this.data.period === 'year') {
      max_chart_days = 365;
    }

    //生成空的日期序列，用于各表的日期轴
    var today = new Date(reports[0].end * 1000); //TODO：注意：这里使用第一份有效报告作为横轴最新节点，可能会导致跟取数据库的时间区间对不齐
    today.setHours(0, 0, 0, 0);
    var todayTS = today.getTime() / 1000; //按今天零点对齐
    resetDates(); //先清空
    for (i = 0; i < max_chart_days; i++) {
      var shortDate = formatDateString(todayTS * 1000, "yyyyMMdd", false);
      chart_dates[shortDate] = -1;
      todayTS -= 24 * 3600;
    }
    //在空日期序列中填充有效报告数据的索引
    todayTS = today.getTime() / 1000; //重新按今天零点对齐
    reports.forEach((value, index, array) => {
      var shortDate = formatDateString(value.end * 1000, "yyyyMMdd", false);
      if (chart_dates[shortDate] === -1) {
        chart_dates[shortDate] = index;
      } else if (chart_dates[shortDate] >= 0) {
        let idx = chart_dates[shortDate];
        if (array[idx].report_time < value.report_time) {
          chart_dates[shortDate] = index;
        }
      }
    });
    //去掉日期序列中开头的空白段
    var keysDate = Object.keys(chart_dates).reverse();
    for (i = 0; i < keysDate.length; i++) {
      if (chart_dates[keysDate[i]] >= 0) {
        break;
      } else if (i < keysDate.length - 29) { // 保留后 14 天，哪怕空白，为了图表不要太短
        delete chart_dates[keysDate[i]];
      }
    }
    //console.log(chart_dates);

  }
}

function initChartDates(reports, reportKey = '') {
  if (!reports) return;

  const max_chart_days = 30;
  let today;

  try {
    if (reportKey) {
      today = new Date(reportKey);
      if (isNaN(today.getTime())) {
        throw new Error('Invalid date');
      }
    } else {
      today = new Date();
    }

    today.setHours(0, 0, 0, 0);
    let todayTS = today.getTime() / 1000;
    resetDates();

    for (let i = 0; i < max_chart_days; i++) {
      const shortDate = formatDateString(todayTS * 1000, 'MMdd', false);
      chart_dates[shortDate] = -1;
      todayTS -= 24 * 3600;
    }
  } catch (error) {
    console.error('Error initializing chart dates:', error);
  }
}
// hrv趋势
const doHrvBlChart = () => {
  try {
    const hrv_bl_canvas = document.getElementById(HrvChartId.value)
    const hrv_bl_chart = echarts.init(hrv_bl_canvas);
    initChartDates(reports_data.value, props.date)
    setHRVChartOption(reports_data.value, hrv_bl_chart, chart_dates);
    
    // 添加加载完成标记
    chartsLoaded.value.hrvChart = true
    checkAllChartsLoaded()
    
    return hrv_bl_chart;
  } catch (error) {
    console.error('Failed to initialize HRV chart:', error)
    chartsLoaded.value.hrvChart = true
    checkAllChartsLoaded()
  }
}
//ahi 趋势
const doAhiChart = () => {
  try {
    const ahi_canvas = document.getElementById(AHiChartId.value)
    const ahi_chart = echarts.init(ahi_canvas);
    parseReportsToDisplay(reports_data.value)
    setAHIChartOption(reports_data.value, ahi_chart, chart_dates);
    
    // 添加加载完成标记
    chartsLoaded.value.ahiChart = true
    checkAllChartsLoaded()
    
    return ahi_chart;
  } catch (error) {
    console.error('Failed to initialize AHI chart:', error)
    chartsLoaded.value.ahiChart = true
    checkAllChartsLoaded()
  }
}


// 组件卸载时清理图表实例
onUnmounted(() => {
  if (chart.value) {
    chart.value.dispose()
    chart.value = null
  }
  window.removeEventListener('resize', () => {
    chart.value?.resize()
  })
  window.removeEventListener('resize', sendPageHeight)
})

const getHrvNote = (reportsData) => {
  if (!reportsData || typeof reportsData !== 'object') return '';

  // 将对象转换为数组并按日期排序
  const reports = Object.entries(reportsData)
    .map(([date, data]) => ({
      date,
      ...data
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  let consecutiveDays = 0;
  
  for (const report of reports) {
    if (report.hrv_rmssd_mean < report.hrv_limit_min) {
      consecutiveDays++;
    } else {
      break;
    }
  }
  
  if (consecutiveDays >= 3) {
    return "近期睡眠HRV连续偏低，建议适当增加休息时间，调整作息规律，尝试放松身心，如进行冥想或深呼吸练习";
  }
  return '';
};
// HRV统计数据
const hrvStat = ref({
  low: 0,
  high: 0,
  labelLow: '-',
  labelHigh: '-',
  value: 0,
  valid: false
})
// 计算 HRV 统计数据
const calculateHrvStat = (reports) => {

  // 将对象转为值数组，并按日期排序
  const reportArray = Object.entries(reports)
    .sort((a, b) => new Date(a[0]) - new Date(b[0])) // 按日期升序排序
    .map(item => item[1]) // 提取值部分

  if (!reportArray.length) return

  // 取第一个记录
  const report = reportArray[reportArray.length - 1]
  hrvStat.value = {
    low: Math.round(report.hrv_limit_min || 0),
    high: Math.round(report.hrv_limit_max || 0),
    labelLow: `${Math.round(report.hrv_limit_min || 0) || '-'}ms`,
    labelHigh: `${Math.round(report.hrv_limit_max || 0) || '-'}ms`,
    value: Math.round(report.hrv_rmssd_mean || 0),
    valid: Boolean(report.hrv_limit_min && report.hrv_limit_max && report.hrv_rmssd_mean),
  }
}

// 添加发送页面高度的函数
const sendPageHeight = () => {
  const height = document.documentElement.scrollHeight || document.body.scrollHeight
  window.parent.postMessage({ type: 'setHeight', height }, '*')
}

// 检查所有图表是否都加载完成
const checkAllChartsLoaded = () => {
  const allLoaded = Object.values(chartsLoaded.value).every(loaded => loaded)
  if (allLoaded) {
    nextTick(() => {
      setTimeout(() => {
        const height = document.documentElement.scrollHeight || document.body.scrollHeight
        window.parent.postMessage({ 
          type: 'setHeight', 
          height,
          status: 'complete',
          hasData: true
        }, '*')
      }, 100)
    })
  }
}

const getAHINote = (ahi) => {
  if (!ahi && ahi !== 0) return { text: '', riskText: '', suggestion: '', color: '' };
  
  let riskLevel = '';
  let color = '';
  
  if (ahi < 5) {
    riskLevel = '未显示阻塞性睡眠呼吸暂停迹象';
    color = '#3CB043'; // 绿色
  } else if (ahi < 15) {
    riskLevel = '疑似轻度阻塞性睡眠呼吸暂停';
    color = '#FFB74D'; // 橙黄色
  } else if (ahi < 30) {
    riskLevel = '疑似中度阻塞性睡眠呼吸暂停';
    color = '#FF9800'; // 橙色
  } else {
    riskLevel = '疑似重度阻塞性睡眠呼吸暂停';
    color = '#F44336'; // 红色
  }

  let suggestion = '';
  if (ahi < 5) {
    suggestion = '近期AHI数据未显示阻塞性睡眠呼吸暂停迹象，建议持续关注这一指标，保持良好的生活习惯。';
  } else if (ahi < 30) {
    suggestion = '近期AHI数据显示疑似轻度阻塞性睡眠呼吸暂停，建议及时调整生活方式，如避免仰卧睡姿、减少烟酒摄入、控制体重、避免摄入镇静类药物等，并' + 
      (ahi < 15 ? '在必要的时候前往专业医疗机构进行进一步咨询和检查' : '尽快前往专业医疗机构进行详细检查获得治疗建议');
  } else {
    suggestion = '近期AHI数据显示疑似重度阻塞性睡眠呼吸暂停，建议尽快就医进行详细诊断，并与医生讨论适合的治疗方案，如使用持续正压通气（CPAP）设备或其他医学干预措施。';
  }

  return {
    text: `(近期AHI数据显示${riskLevel}，${suggestion})`,
    riskText: riskLevel,
    suggestion,
    color
  };
};

const getEnvironmentStatus = (type, value) => {
  const L = {
    status_high: '偏高',
    status_low: '偏低',
    status_optimal: '适宜'
  }
  const ledColor = ['#4CAF50', '#FF9800'] // 正常绿色，异常橙色

  if (type === 'illu') {
    if (value > 15) {
      return { msg: L.status_high, color: ledColor[1], ok: false }
    } else {
      return { msg: L.status_optimal, color: ledColor[0], ok: true }
    }
  } else if (type === 'temp') {
    if (value < 15) {
      return { msg: L.status_low, color: ledColor[1], ok: false }
    } else if (value > 23) {
      return { msg: L.status_high, color: ledColor[1], ok: false }
    } else {
      return { msg: L.status_optimal, color: ledColor[0], ok: true }
    }
  } else if (type === 'humi') {
    if (value < 40) {
      return { msg: L.status_low, color: ledColor[1], ok: false }
    } else if (value > 60) {
      return { msg: L.status_high, color: ledColor[1], ok: false }
    } else {
      return { msg: L.status_optimal, color: ledColor[0], ok: true }
    }
  }
  return { msg: '--', color: ledColor[0], ok: true }
};

</script>

<style scoped>
.sleep-analysis {
  padding: 16px;
  background: #F5F7F8;  /* 修改背景颜色 */
}

.analysis-module {
  margin-bottom: 16px;
}

.module-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.header-left {
  display: flex;
  align-items: center;
}

.module-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.module-title {
  font-size: 16px;
  font-weight: 500;
}

.info-icon {
  color: #999;
  font-size: 14px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 16px;
}

.env-stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 16px;
}

.stat-item {
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
}

.stat-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.stat-value {
  font-size: 16px;
  font-weight: 500;
}

.stat-status {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}

.stat-value-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.content-card {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
}

.sleep-types {
  padding: 10px 15px;
}

.type-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.06);
  margin: 0 15px;
}

.type-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.wake { background: #FF6B6B; }
.rem { background: #6aa9dd; }
.light { background: #4279f0; }
.deep { background: #353799; }

.type-label {
  flex: 1;
  font-size: 14px;
  color: #666;
}

.type-value {
  font-size: 14px;
  color: #333;
}

.stage-chart {
  width: 100%;
  height: 70%;
  margin-bottom: 8px;
}

.art-chart {
  width: 100%;
  height: 30%;
}

.card-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.main-value {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}

.value-note {
  font-size: 14px;
  color: #666;
  white-space: pre-line;
  line-height: 1.5;
  margin: 8px 0;
}

.environment-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 16px;
}

.env-item {
  text-align: center;
}

.env-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.env-value {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.env-status {
  font-size: 12px;
  font-weight: 500;
}

.hrv-chart,
.breathing-chart,
.environment-chart {
  width: 100%;
  height: 200px;
  margin-top: 16px;
}

/* Loading styles */
.van-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 自定义遮罩层样式 */
:deep(.transparent-overlay) {
  backdrop-filter: blur(2px);  /* 添加轻微模糊效果 */
  transition: all 0.3s;        /* 添加过渡效果 */
}
</style>

