<template>
  <div class="questionnaire-content">
    <div class="bg-white">
      <!-- 问卷说明 -->
      <header class="mb-8">
        <h1 class="text-2xl font-bold mb-4">广泛性焦虑量表 (GAD-7)</h1>
        <div class="text-gray-600 space-y-2">
          <p>下面的问题是想了解近两周内您的焦虑状况如何。根据下面7个问题回答，选择符合您的选项的数字。</p>
        </div>
      </header>

      <!-- 问卷内容 -->
      <el-form ref="formRef" :model="formData" label-position="top" class="space-y-8">
        <div class="bg-gray-50 p-8 rounded-lg space-y-8">
          <el-form-item label="姓名：" class="form-item-custom">
            <el-input 
              v-model="formData.patientName" 
              placeholder="请输入姓名"
              class="custom-input w-64"
            />
          </el-form-item>

          <div class="border rounded-lg overflow-hidden">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-6 py-3 text-left text-sm font-medium text-gray-500">序号</th>
                  <th class="px-6 py-3 text-left text-sm font-medium text-gray-500">问题描述</th>
                  <th class="px-6 py-3 text-sm font-medium text-gray-500">评分</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(item, index) in gadQuestions" :key="index" 
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ index + 1 }}
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-900">
                    {{ item.question }}
                  </td>
                  <td class="px-6 py-4">
                    <el-radio-group v-model="formData.answers[index]">
                      <el-radio :label="0">完全不会</el-radio>
                      <el-radio :label="1">几天</el-radio>
                      <el-radio :label="2">一半以上的日子</el-radio>
                      <el-radio :label="3">几乎每天</el-radio>
                    </el-radio-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 操作按钮 -->
          <div class="flex justify-end gap-4 mt-8">
            <el-button @click="$emit('close')">取消</el-button>
            <el-button type="primary" @click="handleSubmit">保存</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { createAxiosInstance } from '@/plugins/axios.js'
import { emitter } from '@/plugins/eventBus'

const props = defineProps({
  deviceId: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close', 'submit'])

// GAD-7 问题列表
const gadQuestions = [
  { question: '感觉紧张，焦虑或烦躁' },
  { question: '不能够停止或控制担忧' },
  { question: '对各种各样的事情担忧过多' },
  { question: '很难放松下来' },
  { question: '由于不安而无法静坐' },
  { question: '变得容易烦恼或急躁' },
  { question: '感到似乎将有可怕的事情发生而害怕' }
]

// 表单数据
const formData = ref({
  patientName: '',
  answers: new Array(7).fill(null)
})

// 计算总分
const totalScore = computed(() => {
  if (formData.value.answers.includes(null)) return null
  return formData.value.answers.reduce((sum, score) => sum + score, 0)
})

// 计算分数颜色类
const scoreColorClass = computed(() => {
  if (totalScore.value === null) return ''
  if (totalScore.value <= 4) return 'text-green-600'
  if (totalScore.value <= 9) return 'text-yellow-600'
  if (totalScore.value <= 14) return 'text-orange-600'
  return 'text-red-600'
})

// 计算结果文本
const resultText = computed(() => {
  if (totalScore.value === null) return ''
  if (totalScore.value <= 4) return '无焦虑'
  if (totalScore.value <= 9) return '轻度焦虑'
  if (totalScore.value <= 14) return '中度焦虑'
  return '重度焦虑'
})

// 表单验证
const validateForm = () => {
  if (!formData.value.patientName) {
    ElMessage.error('请输入姓名')
    return false
  }

  if (formData.value.answers.some(answer => answer === null)) {
    ElMessage.error('请回答所有问题')
    return false
  }

  return true
}

// 日期格式化
const formatDate = (dateString) => {
  if (!dateString) return ''
  if (dateString.includes('%20')) {
    return dateString.split('%20')[0]
  }
  if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return dateString
  }
  const date = new Date(dateString)
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

// 重置表单
const resetForm = () => {
  formData.value = {
    patientName: '',
    answers: new Array(7).fill(null)
  }
}

// 提交问卷
const handleSubmit = async () => {
  if (!validateForm()) return

  try {
    const formattedDate = formatDate(props.date)
    const submitData = {
      data: {
        patientName: formData.value.patientName,
        answers: formData.value.answers
      },
      totalScore: totalScore.value
    }
    console.log(submitData)
    const axios = createAxiosInstance('计算服务器1')
    const response = await axios.post(
      `/questionnaire/${props.deviceId}/${formattedDate}/gad7`,
      submitData
    )

    if (response.data.status === 'success') {
      emitter.emit('questionnaire-updated', {
        type: 'gad7',
        date: formattedDate,
        deviceId: props.deviceId
      })
      emit('submit')
    }
  } catch (error) {
    console.error('保存问卷失败:', error)
    ElMessage.error('保存失败，请重试')
  }
}

// 获取历史数据
const fetchQuestionnaireData = async () => {
  try {
    const formattedDate = formatDate(props.date)
    const axios = createAxiosInstance('计算服务器1')
    const response = await axios.get(`/questionnaire/${props.deviceId}/${formattedDate}/gad7`)
    
    if (response.data.status === 'success' && response.data.data) {
      const { data } = response.data.data
      formData.value = {
        patientName: data.patientName || '',
        answers: data.answers || new Array(7).fill(null)
      }
    } else {
      resetForm()
    }
  } catch (error) {
    if (error.response?.status === 404) {
      resetForm()
    } else {
      console.error('获取问卷数据失败:', error)
    }
  }
}

// 监听日期变化
watch(() => props.date, () => {
  fetchQuestionnaireData()
})

// 组件挂载时获取数据
onMounted(() => {
  fetchQuestionnaireData()
})
</script>

<style scoped>
.questionnaire-content {
  max-height: 70vh;
  overflow-y: auto;
  padding: 1rem;
}

.el-radio-group {
  display: flex;
  gap: 1rem;
}

.el-radio {
  margin-right: 0;
}

.el-form-item {
  margin-bottom: 1.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
}

tbody tr:hover {
  background-color: #f9fafb;
}
</style>
