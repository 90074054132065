import { getColor, seconds2HHmm } from '@/plugins/utils/utils.js';
import { locales } from '@/plugins/utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

export function setStageCompChartOption (reports, deep_chart, chart_dates) {
  if (!deep_chart) {
    return;
  }

  const dataWake = [];
  const dataREM = [];
  const dataCore = [];
  const dataDeep = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(reports).length;
    // 如果周期太长，以周为单位计算均值
    var wake, rem, core, deep;

    if (total <= 60) {
      for (const key in chart_dates) {

        const [year,month, day] = key.split('/');
        let abbrKey;
        if (day === undefined) {
            abbrKey = year + '-' + month;
        } else {
            abbrKey = month + '-' + day;
        }
        // const abbrKey = month + '-' + day;
        if (reports[key] && Object.keys(reports[key]).length > 0) {
          wake = reports[key].sleep_dur_1;
          rem = reports[key].sleep_dur_2;
          core = reports[key].sleep_dur_3 + reports[key].sleep_dur_4
          deep = reports[key].sleep_dur_5;
        } else {
          wake = null;
          rem = null;
          core = null;
          deep = null;
        }
        dataWake.push({ name: abbrKey, value: wake });
        dataREM.push({ name: abbrKey, value: rem });
        dataCore.push({ name: abbrKey, value: core });
        dataDeep.push({ name: abbrKey, value: deep });
        dataXAxis.push(abbrKey);
      }
    } else {
      weekmode = true;
      let [wake_avg, rem_avg, core_avg, deep_avg, count] = [0, 0, 0, 0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      for (let key in chart_dates) {
        const [year, month, day] = key.split('/');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();
        if (reports[key] && Object.keys(reports[key]).length > 0) {
          wake = reports[key].sleep_dur_1;
          rem = reports[key].sleep_dur_2;
          core = reports[key].sleep_dur_3 + reports[key].sleep_dur_4
          deep = reports[key].sleep_dur_5;
        } else {
          wake = null;
          rem = null;
          core = null;
          deep = null;
        }
        if (wake != null && rem != null && core != null && deep != null && valid) {
          wake_avg += wake;
          rem_avg += rem;
          core_avg += core;
          deep_avg += deep;
          count++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            wake_avg = count ? Math.round(wake_avg / count) : null;
            rem_avg = count ? Math.round(rem_avg / count) : null;
            core_avg = count ? Math.round(core_avg / count) : null;
            deep_avg = count ? Math.round(deep_avg / count) : null;

            key = month + '-' + day + L.to + weekend;
            dataWake.push({ name: key, value: wake_avg });
            dataREM.push({ name: key, value: rem_avg });
            dataCore.push({ name: key, value: core_avg });
            dataDeep.push({ name: key, value: deep_avg });
            dataXAxis.push(key);
            [wake_avg, rem_avg, core_avg, deep_avg, count] = [0, 0, 0, 0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataWake.reverse();
  dataREM.reverse();
  dataCore.reverse();
  dataDeep.reverse();
  dataXAxis.reverse();

  const screenWidth = 0;

  const option = {
    backgroundColor: 'transparent',
    animationDuration: 500,
    /* title: {
          text: '睡眠阶段的时长',
          padding: [0, 0, 0, 36],
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14,
          }
        }, */
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesDesc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      valueFormatter: (value) => value ? seconds2HHmm(value * 60, true) : '-'
      /* formatter: function (params) {
                console.log(params);
                return params[0].marker + params[0].seriesName + ': ' + seconds2HHmm(params[0].value*60, true) + '\n'
                    + params[1].marker + params[1].seriesName + ': ' + seconds2HHmm(params[1].value*60, true) + '\n'
                    + params[2].marker + params[2].seriesName + ': ' + seconds2HHmm(params[2].value*60, true) + '\n'
                    + params[3].marker + params[3].seriesName + ': ' + seconds2HHmm(params[3].value*60, true)
            } */
    },
    legend: {
      data: [L.wake, L.rem, L.light, L.deep],
      orient: 'horizontal',
      bottom: -5,
      selectedMode: true,
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 20,
      itemGap: 6,
      textStyle: {
        fontSize: 12,
        padding: [0, 0, 0, -6]
      }
    },
    grid: {
      top: '5%',
      left: '2%',
      right: '2%',
      bottom: screenWidth <= 320 ? '24%' : '12%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      axisPointer: { z: 100 },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : 1,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth != endMonth || startDay == 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: [{
      type: 'value',
      min: 0,
      interval: 120,
      minInterval: 120,
      axisLabel: {
        formatter: (value) => Math.round(value / 60) + L.time_hr
      }
    }],
    series: [
      {
        name: L.deep,
        type: 'bar',
        stack: 'Total',
        areaStyle: { color: '#36349d' },
        itemStyle: { color: '#36349d' },
        showAllSymbol: 'true',
        data: dataDeep
      },
      {
        name: L.light,
        type: 'bar',
        stack: 'Total',
        areaStyle: { color: '#3478f6' },
        itemStyle: { color: '#3478f6' },
        showAllSymbol: 'true',
        data: dataCore
      },
      {
        name: L.rem,
        type: 'bar',
        stack: 'Total',
        areaStyle: { color: '#59aae0' },
        itemStyle: { color: '#59aae0' },
        showAllSymbol: 'true',
        data: dataREM
      },
      {
        name: L.wake,
        type: 'bar',
        stack: 'Total',
        areaStyle: { color: '#fe8973' },
        itemStyle: { color: '#fe8973' },
        showAllSymbol: 'true',
        data: dataWake
      }
    ]
  };
  deep_chart.setOption(option);
  // console.log("Sleep chart optionized with: " + reports.length + " reports");
}
