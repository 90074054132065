<template>
  <div class="h-screen overflow-y-auto bg-slate-100 p-4 space-y-4">
    <!-- 筛选区域 -->
    <div class="mx-auto bg-white rounded-xl shadow-md border border-slate-200 mb-4 max-w-7xl transition-all duration-300">
      <div class="p-6">
        <!-- 标题和批量操作按钮 -->
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-lg font-semibold text-slate-800">用户管理</h2>
          <div class="flex items-center gap-4">
            <button
              class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @click="toggleBatchMode"
            >
              {{ isBatchMode ? '退出批量模式' : '进入批量模式' }}
            </button>
            <!-- 全选按钮，仅在批量模式下显示 -->
            <button
              v-if="isBatchMode"
              class="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              @click="toggleSelectAll"
            >
              {{ isAllSelected ? '取消全选' : '全选' }}
            </button>
            <!-- 批量解绑按钮 -->
            <button
              v-if="isBatchMode"
              class="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              @click="handleBatchUnbind"
              :disabled="!selectedUsers.length"
            >
              批量解绑
            </button>
            <!-- 下载报告按钮，仅在批量模式下显示 -->
            <button
              v-if="isBatchMode"
              class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              @click="prepareDownloadReports"
              :disabled="!selectedUsers.length"
            >
              下载报告
            </button>
          </div>
        </div>
        
        <!-- 筛选区域 -->
        <div class="space-y-4">
          <!-- 搜索框 -->
          <div class="flex items-center gap-3">
            <span class="text-slate-600 font-medium min-w-[80px]">搜索：</span>
            <div class="relative flex-1 max-w-xl">
              <input
                v-model="searchQuery"
                type="text"
                placeholder="搜索用户名/手机号/设备ID..."
                class="w-full px-4 py-2.5 bg-white border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none shadow-sm text-base"
                @input="handleSearch"
              />
              <svg 
                v-if="!searchQuery"
                class="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-slate-400"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <button
                v-else
                @click="clearSearch"
                class="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-slate-400 hover:text-slate-600 transition-colors"
              >
                <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          <!-- 设备状态筛选 -->
          <div class="flex items-center gap-3">
            <span class="text-slate-600 font-medium min-w-[80px]">设备状态：</span>
            <el-radio-group v-model="deviceBindStatus" @change="handleDeviceStatusChange" class="flex-1">
              <el-radio-button label="" class="!rounded-l-lg">全部</el-radio-button>
              <el-radio-button label="bound">已绑定设备</el-radio-button>
              <el-radio-button label="unbound" class="!rounded-r-lg">未绑定设备</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>

    <!-- 用户列表 -->
    <div class="mx-auto max-w-7xl bg-white rounded-lg shadow-md border border-slate-200">
      <div class="p-6">
        <!-- 用户列表表格 -->
        <el-table 
          :data="rows" 
          style="width: 100%" 
          border 
          class="custom-table"
          :max-height="tableMaxHeight"
          @selection-change="handleSelectionChange"
        >
          <!-- 选择列，仅在批量模式下显示 -->
          <el-table-column
            v-if="isBatchMode"
            type="selection"
            width="55"
            :selectable="row => !!row.device_监测垫?.deviceID"
          />
          <!-- 用户名列 -->
          <el-table-column label="用户名" min-width="120">
            <template #default="scope">
              <div class="flex items-center gap-2">
                <span class="font-medium" :class="scope.row.device_监测垫?.username ? 'text-slate-700' : 'text-slate-400 italic'">
                  {{ scope.row.device_监测垫?.username || '暂无用户名' }}
                </span>
              </div>
            </template>
          </el-table-column>

          <!-- 手机号列 -->
          <el-table-column label="手机号" min-width="140">
            <template #default="scope">
              <span :class="scope.row.device_监测垫?.phoneNumber ? 'text-slate-600' : 'text-slate-400 italic'">
                {{ scope.row.device_监测垫?.phoneNumber || scope.row.device_监测垫?.user || '暂无手机号' }}
              </span>
            </template>
          </el-table-column>

          <!-- 设备绑定列 -->
          <el-table-column label="绑定设备" min-width="180">
            <template #default="scope">
              <div class="flex items-center gap-2">
                <div class="flex items-center gap-2">
                  <span 
                    :class="[
                      'px-2 py-1 rounded-md text-sm font-medium cursor-pointer hover:bg-opacity-75 transition-colors',
                      scope.row.device_监测垫?.deviceID 
                        ? 'bg-green-50 text-green-600 hover:bg-green-100' 
                        : 'bg-slate-50 text-slate-600'
                    ]"
                    @click="handleDeviceClick(scope.row.device_监测垫?.deviceID)"
                  >
                    {{ scope.row.device_监测垫?.deviceID || '未绑定设备' }}
                  </span>
                  <el-button
                    v-if="scope.row.device_监测垫?.deviceID"
                    @click="handleUnbind(scope.row)"
                    type="danger"
                    size="small"
                    :loading="unbindLoading"
                    class="!flex !items-center !gap-1"
                  >
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                    </svg>
                    解绑
                  </el-button>
                </div>
              </div>
            </template>
          </el-table-column>

          <!-- 基本信息列 -->
          <el-table-column label="基本信息" min-width="280">
            <template #default="scope">
              <div class="grid grid-cols-2 gap-3">
                <div class="flex items-center gap-2">
                  <span class="text-slate-500">性别:</span>
                  <span :class="[
                    'px-2 py-0.5 rounded text-sm',
                    scope.row.device_监测垫?.sex 
                      ? 'bg-blue-50 text-blue-600 font-medium' 
                      : 'bg-slate-50 text-slate-400 italic'
                  ]">
                    {{ scope.row.device_监测垫?.sex || '未设置' }}
                  </span>
                </div>
                <div class="flex items-center gap-2">
                  <span class="text-slate-500">生日:</span>
                  <span :class="scope.row.device_监测垫?.birthdate ? 'font-medium text-slate-700' : 'text-slate-400 italic'">
                    {{ scope.row.device_监测垫?.birthdate || '未设置' }}
                  </span>
                </div>
                <div class="flex items-center gap-2">
                  <span class="text-slate-500">身高:</span>
                  <span :class="scope.row.device_监测垫?.height ? 'font-medium text-slate-700' : 'text-slate-400 italic'">
                    {{ scope.row.device_监测垫?.height ? `${scope.row.device_监测垫.height}cm` : '未设置' }}
                  </span>
                </div>
                <div class="flex items-center gap-2">
                  <span class="text-slate-500">体重:</span>
                  <span :class="scope.row.device_监测垫?.weight ? 'font-medium text-slate-700' : 'text-slate-400 italic'">
                    {{ scope.row.device_监测垫?.weight ? `${scope.row.device_监测垫.weight}kg` : '未设置' }}
                  </span>
                </div>
                <div class="flex items-center gap-2 col-span-2">
                  <span class="text-slate-500">BMI:</span>
                  <span :class="[
                    'px-2 py-0.5 rounded font-medium',
                    getBMIColorClass(calculateBMI(scope.row.device_监测垫?.height, scope.row.device_监测垫?.weight))
                  ]">
                    {{ calculateBMI(scope.row.device_监测垫?.height, scope.row.device_监测垫?.weight) }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <!-- 操作列 -->
          <el-table-column label="操作" width="100" fixed="right">
            <template #default="scope">
              <el-button 
                @click="openEditDialog(scope.row)" 
                type="primary" 
                size="small"
                class="!flex !items-center !gap-1"
              >
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <div class="mx-auto max-w-7xl mt-6 flex justify-center p-4 bg-white rounded-lg shadow-sm border border-slate-200">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        class="!text-sm"
      />
    </div>

    <!-- 编辑对话框 -->
    <el-dialog
      v-model="editDialogVisible"
      title="编辑用户信息"
      width="500px"
      class="custom-dialog"
    >
      <el-form :model="editForm" label-width="100px" class="add-user-form">
        <el-form-item label="用户名">
          <el-input v-model="editForm.username" placeholder="请输入用户名" />
        </el-form-item>
        <el-form-item label="手机号">
          <span class="text-slate-600">{{ editForm.user }}</span>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="editForm.device_监测垫.sex" placeholder="请选择性别" class="!w-full">
            <el-option label="男" value="男" />
            <el-option label="女" value="女" />
          </el-select>
        </el-form-item>
        <el-form-item label="生日">
          <el-date-picker
            v-model="editForm.device_监测垫.birthdate"
            type="month"
            placeholder="选择生日"
            format="YYYY-MM"
            value-format="YYYY-MM"
            class="!w-full"
          />
        </el-form-item>
        <el-form-item label="身高">
          <el-input-number 
            v-model="editForm.device_监测垫.height"
            :min="0"
            :max="300"
            :step="1"
            class="!w-[180px]"
          >
            <template #suffix>cm</template>
          </el-input-number>
        </el-form-item>
        <el-form-item label="体重">
          <el-input-number 
            v-model="editForm.device_监测垫.weight"
            :min="0"
            :max="500"
            :step="1"
            class="!w-[180px]"
          >
            <template #suffix>kg</template>
          </el-input-number>
        </el-form-item>
        <el-form-item label="BMI">
          <span :class="[
            'px-2 py-0.5 rounded font-medium',
            getBMIColorClass(calculateBMI(editForm.device_监测垫?.height, editForm.device_监测垫?.weight))
          ]">
            {{ calculateBMI(editForm.device_监测垫?.height, editForm.device_监测垫?.weight) }}
          </span>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="flex justify-end gap-3">
          <el-button @click="editDialogVisible = false" class="!px-6">取消</el-button>
          <el-button type="primary" @click="handleSubmit" class="!px-6">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 日期选择弹窗 -->
    <el-dialog
      v-model="showDatePicker"
      title="选择日期范围"
      width="30%"
      destroy-on-close
    >
      <div class="flex flex-col items-center">
        <!-- 添加提示信息 -->
        <div class="w-full mb-4 p-3 bg-blue-50 text-blue-700 rounded-md">
          <div class="flex items-start">
            <svg class="w-5 h-5 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>
              提示：如果不选择日期范围，将下载所有历史报告数据。
            </span>
          </div>
        </div>
        
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          class="w-full mb-4"
        />
        <div class="flex justify-end w-full mt-4">
          <el-button @click="showDatePicker = false" class="mr-2">取消</el-button>
          <el-button type="primary" @click="confirmDownload">确认下载</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch ,computed, reactive} from 'vue';

import { useStore } from 'vuex'

import { useRouter } from 'vue-router';
// const {proxy} = getCurrentInstance();
import {createAxiosInstance, wdb_get_history_new,request_data, wdb_get_new} from "@/plugins/axios.js";
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';
const router = useRouter();
const store = useStore();
// 定义响应式数据
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(100);
const rows = ref([]);
const formType = ref('');
const formTitle = ref('');
let intervalId = null; // 用于存储定时器ID
const row_data = ref({})
const serverType = ref('数据服务器')
const user = computed(() => store.state.user)
console.log(user.value)
let encodedFilterStr = '';
const org_bind = computed(() => user.value.org)

// 获取用户信息，修改用户信息
function handleEditDialogClose (visible) {
  console.log(visible)
  editDialogVisible.value = false;
}

// 收藏夹模块

const editDialogVisible = ref(false);
const editForm = ref({
  username: null,
  user: null,
  device_监测垫: {
    sex: null,
    birthdate: null,
    height: null,
    weight: null,
    deviceID: null
  }
});

const openEditDialog = async (rowData) => {
  editDialogVisible.value = true;
  try {
    const response = await request_data(`/userinfo/${rowData.device_监测垫?.user || rowData.device_监测垫?.phoneNumber || ''}/`);
    editForm.value = {
      ...response,
      username: response?.device_监测垫?.username || null,
      user: response?.device_监测垫?.user || response?.device_监测垫?.phoneNumber || null,
      device_监测垫: {
        sex: response?.device_监测垫?.sex || null,
        birthdate: response?.device_监测垫?.birthdate || null,
        height: Number(response?.device_监测垫?.height) || null,
        weight: Number(response?.device_监测垫?.weight) || null,
        deviceID: response?.device_监测垫?.deviceID || null,
        openid: response?.device_监测垫?.openid || null
      }
    };
  } catch (error) {
    console.error('获取用户信息失败:', error);
    // 如果获取失败，使用行数据初始化表单
    editForm.value = {
      username: rowData?.device_监测垫?.username || null,
      user: rowData?.device_监测垫?.user || rowData?.device_监测垫?.phoneNumber || null,
      device_监测垫: {
        sex: rowData?.device_监测垫?.sex || null,
        birthdate: rowData?.device_监测垫?.birthdate || null,
        height: Number(rowData?.device_监测垫?.height) || null,
        weight: Number(rowData?.device_监测垫?.weight) || null,
        deviceID: rowData?.device_监测垫?.deviceID || null,
        openid: rowData?.device_监测垫?.openid || null
      }
    };
  }
  formType.value = 'edit';
  formTitle.value = '编辑用户信息';
};

// 从云端获取数据的方法
const fetchData = async () => {
  try {
    let filterParts = [];
    
    // 修改设备绑定状态过滤逻辑
    if (deviceBindStatus.value === 'bound') {
      filterParts.push('bound');  // 修改这里
    } else if (deviceBindStatus.value === 'unbound') {
      filterParts.push('unbound');  // 修改这里
    }
    
    // 添加搜索过滤
    if (searchQuery.value.trim()) {
      filterParts.push(`${searchQuery.value.trim()}`);
    }
    
    // 组合所有过滤条件
    const filterStr = filterParts.join(' AND ');
    
    // 修改为新的API路径，添加org和pagesize参数
    const response = await request_data(
      `/org_users/?org=${org_bind.value}&filter=${encodeURIComponent(filterStr)}&page=${currentPage.value}&pagesize=${pageSize.value}`
    );
    
    // 添加调试日志
    console.log('API响应数据:', response);
    
    // 检查响应结构并适当处理
    if (response && response.data) {
      rows.value = response.data.nodes;
      total.value = response.data.count;
    } else if (response && response.nodes) {
      // 如果数据直接在response根级别
      rows.value = response.nodes;
      total.value = response.count;
    } else {
      // 如果无法识别的数据结构，记录错误
      console.error('未知的响应数据结构:', response);
      ElMessage.error('获取数据失败：响应格式异常');
    }
  } catch (error) {
    console.error('获取数据失败:', error);
    ElMessage.error('获取数据失败');
  }
};

// 处理响应数据

// 处理过滤器变化
const handleFilterChange = async (filterStr) => {
  encodedFilterStr = encodeURIComponent(filterStr);
  fetchData();
  // store.commit('setFilter', filterStr);
};

// 处理分页大小变化
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};

// 处理当前页变化
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

// 排序方法
const sortNames = (a, b) => {
  return (a.user + '').localeCompare(b.user + '');
};
onMounted(async () => {
  fetchData()
  // intervalId = setInterval(fetchData, 2000);
});
onUnmounted(() => {
  console.log('unmoounted main')
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

const selectedTags = reactive({
  角色: []
})

const searchQuery = ref('')
const searchDebounceTimer = ref(null)

// 处理搜索
const handleSearch = () => {
  if (searchDebounceTimer.value) {
    clearTimeout(searchDebounceTimer.value)
  }
  
  searchDebounceTimer.value = setTimeout(() => {
    fetchData()
  }, 300)
}

// 清除搜索
const clearSearch = () => {
  searchQuery.value = ''
  handleSearch()
}

// 添加 BMI 计算函数
const calculateBMI = (height, weight) => {
  if (!height || !weight || isNaN(height) || isNaN(weight)) return '-';
  const heightInMeters = height / 100;
  const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(1);
  return isNaN(bmi) ? '-' : bmi;
};

// 修改 handleSubmit 方法
const handleSubmit = async () => {
  try {
    const path = `/update_user/${editForm.value.user}/`;
    const axiosInstance = createAxiosInstance("数据服务器");

    // 构建请求体
    const requestBody = {
      username: editForm.value.username || '',
      gender: editForm.value.device_监测垫?.sex || '',
      height: editForm.value.device_监测垫?.height?.toString() || '',
      weight: editForm.value.device_监测垫?.weight?.toString() || '',
      birthDate: editForm.value.device_监测垫?.birthdate || '',
      openId: editForm.value.device_监测垫?.openid || ''
    };

    const response = await axiosInstance.post(path, requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    console.log(response);
    editDialogVisible.value = false;
    ElMessage.success('用户更新成功');
    
    // 刷新数据
    fetchData();

  } catch (error) {
    console.error('更新失败:', error);
    ElMessage.error('更新失败');
  }
};

// 添加 BMI 颜色样式类函数
const getBMIColorClass = (bmi) => {
  if (bmi === '-') return 'bg-slate-50 text-slate-500';
  const bmiValue = parseFloat(bmi);
  if (bmiValue < 18.5) return 'bg-yellow-50 text-yellow-600';
  if (bmiValue >= 18.5 && bmiValue < 24) return 'bg-green-50 text-green-600';
  if (bmiValue >= 24 && bmiValue < 28) return 'bg-orange-50 text-orange-600';
  return 'bg-red-50 text-red-600';
};

const unbindLoading = ref(false);
const bindForm = ref({
  device: '',
  user: '',
  openid: ''
});

const handleUnbind = async (row) => {
  try {
    // 显示确认对话框
    await ElMessageBox.confirm('确定要解除设备绑定吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });

    unbindLoading.value = true;
    bindForm.value.device = row.device_监测垫.deviceID;
    bindForm.value.user = row.device_监测垫.user || row.device_监测垫.phoneNumber;
    bindForm.value.openid = row.device_监测垫.openid;

    const axios = createAxiosInstance("数据服务器");
    await axios.post('/unbind/', bindForm.value, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // 延时2秒
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    ElMessage.success('解绑成功');
    fetchData(); // 刷新数据
  } catch (error) {
    if (error !== 'cancel') {
      console.error('解绑失败:', error);
      ElMessage.error('解绑失败');
    }
  } finally {
    unbindLoading.value = false;
  }
};

// 添加表格最大高度计算
const tableMaxHeight = ref(500)

// 计算表格高度的函数
const calculateTableHeight = () => {
  // 预留空间给其他元素（筛选区域、分页等）
  const reservedSpace = 300
  const windowHeight = window.innerHeight
  tableMaxHeight.value = windowHeight - reservedSpace
}

// 监听窗口大小变化
onMounted(() => {
  calculateTableHeight()
  window.addEventListener('resize', calculateTableHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', calculateTableHeight)
})

// 添加设备绑定状态变量
const deviceBindStatus = ref('');

// 添加设备状态变化处理函数
const handleDeviceStatusChange = () => {
  currentPage.value = 1; // 重置页码
  fetchData();
};

// 添加设备点击处理函数
const handleDeviceClick = (deviceID) => {
  if (!deviceID) return; // 如果没有设备ID，不进行跳转
  
  // 跳转到 Dashboard 页面，并带上设备ID参数
  router.push({
    name: 'Devices',
    query: {
      deviceID: deviceID
    }
  });
};

// 批量处理相关的状态
const isBatchMode = ref(false);
const isAllSelected = ref(false);
const selectedUsers = ref([]);
const dateRange = ref([]);
const showDatePicker = ref(false);

// 切换批量模式
const toggleBatchMode = () => {
  isBatchMode.value = !isBatchMode.value;
  if (!isBatchMode.value) {
    // 退出批量模式时重置选择状态
    isAllSelected.value = false;
    selectedUsers.value = [];
  }
};

// 切换全选状态
const toggleSelectAll = () => {
  const table = document.querySelector('.el-table');
  if (table) {
    const toggleCheckbox = table.querySelector('.el-table__header-wrapper .el-checkbox');
    if (toggleCheckbox) {
      toggleCheckbox.click();
    }
  }
};

// 处理表格选择变化
const handleSelectionChange = (selection) => {
  selectedUsers.value = selection;
  isAllSelected.value = selection.length === rows.value.length && rows.value.length > 0;
};

// 准备下载报告
const prepareDownloadReports = () => {
  if (selectedUsers.value.length === 0) {
    ElMessage.warning('请选择至少一个用户');
    return;
  }
  showDatePicker.value = true;
};

// 确认下载
const confirmDownload = async () => {
  showDatePicker.value = false;
  await downloadReports();
};

// 下载选中用户的报告
const downloadReports = async () => {
  const apiBaseUrl = "https://data.wuji.com/a/report/download";
  const devices = selectedUsers.value.map(user => user.device_监测垫?.deviceID).filter(Boolean);
  
  if (devices.length === 0) {
    ElMessage.warning('选中的用户中没有绑定设备');
    return;
  }

  try {
    const rawData = await fetchDataForDevices(apiBaseUrl, devices);
    const processedData = processData(rawData);
    saveToExcel(processedData);
    ElMessage.success('报告下载成功');
  } catch (error) {
    console.error('下载报告失败:', error);
    ElMessage.error('下载报告失败，请重试');
  }
};

// 从接口中获取设备数据
const fetchDataForDevices = async (apiBaseUrl, devices) => {
  const allDeviceData = [];
  for (const deviceId of devices) {
    let apiUrl;
    
    // 根据日期范围构建 URL
    if (dateRange.value && dateRange.value[0] && dateRange.value[1]) {
      // 如果选择了日期范围
      const startDate = dateRange.value[0];
      const endDate = dateRange.value[1];
      apiUrl = `${apiBaseUrl}/${deviceId}/history/?start_date=${startDate}&end_date=${endDate}`;
    } else {
      // 如果没有选择日期范围，则获取所有历史数据
      apiUrl = `${apiBaseUrl}/${deviceId}/history/`;
    }
    
    console.log(`Fetching data for device: ${deviceId} from ${apiUrl}`);
    try {
      const axios = createAxiosInstance("计算服务器1");
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const deviceData = response.data.data || [];
        deviceData.forEach(record => {
          record.device = deviceId; // 将设备ID加入记录中
        });
        allDeviceData.push(...deviceData); // 合并所有设备数据
      } else {
        console.error(`Failed to fetch data for ${deviceId}: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error fetching data for ${deviceId}:`, error);
    }
  }
  return allDeviceData;
};
// 保存为 Excel（不同 Sheet）
const saveToExcel = (processedData) => {
  const workbook = XLSX.utils.book_new();
  for (const [deviceId, records] of Object.entries(processedData)) {
    const worksheet = XLSX.utils.json_to_sheet(records);
    XLSX.utils.book_append_sheet(workbook, worksheet, `设备_${deviceId}`);
  }
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '睡眠报告_多设备.xlsx');
};
// 时间戳转换为 yyyy-mm-dd HH:MM:SS 格式
const formatTimestampToDatetime = (timestamp) => {
  return new Date(timestamp * 1000).toISOString().replace('T', ' ').split('.')[0];
};

// 将 JSON 数据格式化为 Excel 数据
const processData = (data) => {
  const processedData = {};

  data.forEach(record => {
    const deviceId = record.device;
    if (!processedData[deviceId]) {
      processedData[deviceId] = [];
    }

    const row = {
      "日期": record.date,
      "设备ID": record.device,
      "关灯时间": formatTimestampToDatetime(record.reports.online.sleep_intention_time),
      "开灯时间": formatTimestampToDatetime(record.reports.online.intention_leave_time),
      "总睡眠时长 (分钟)": record.reports.online.tst,
      "卧床时长 (分钟)": record.reports.online.tib,
      "入睡时间 (分钟)": record.reports.online.sol,
      "睡眠中断 (分钟)": record.reports.online.waso,
      "深度睡眠 (分钟)": record.reports.online.deep_time,
      "快速眼动潜伏期 (分钟)": record.reports.online.rem_latency,
      "睡眠规律性 (%)": record.reports.online.sri,
      "睡眠碎片化指数": record.reports.online.sfi,
      "清醒 (分钟)": record.reports.online.sleep_dur_1,
      "快速眼动睡眠 (分钟)": record.reports.online.rem_time,
      "浅度睡眠 (分钟)": record.reports.online.core_time,
      "呼吸暂停低通指数 (AHI)": record.reports.online.ahi,
      "NREM AHI": record.reports.online.ahi_345,
      "REM AHI": record.reports.online.ahi_2,
      "鼾声指数 (SI)": record.reports.online.bcgsnore_index,
      "平均呼吸率 (次/分)": record.reports.online.avg_resp,
      "最低心率 (次/分)": record.reports.summmary.min_sleep_heart_rate,
      "平均心率 (次/分)": record.reports.summmary.avg_sleep_heart_rate,
      "HRV_RMSSD (毫秒)": record.reports.summmary.avg_hrv_rmssd,
      "HRV_LF/HF": record.reports.summmary.avg_hrv_radio,
      "平均温度 (°C)": record.reports.summmary.avg_temperature,
      "平均湿度 (%)": record.reports.summmary.avg_humidity,
      "手机号": record.reports.summmary.user,
      "姓名": record.reports.summmary.username,
      "身高": record.reports.summmary.height,
      "体重": record.reports.summmary.weight,
      "性别": record.reports.summmary.sex,
      "出生日期": record.reports.summmary.birthdate,
    };
    processedData[deviceId].push(row);
  });

  return processedData;
};

// 添加并发限制的批量请求函数
const batchRequest = async (items, fn, batchSize = 5) => {
  const results = [];
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    const batchPromises = batch.map(fn);
    const batchResults = await Promise.all(batchPromises);
    results.push(...batchResults);
  }
  return results;
};

// 修改 handleBatchUnbind 中的批量处理部分
const handleBatchUnbind = async () => {
  if (selectedUsers.value.length === 0) {
    ElMessage.warning('请选择要解绑的用户');
    return;
  }

  // 过滤出已绑定设备的用户
  const usersToUnbind = selectedUsers.value.filter(user => user.device_监测垫?.deviceID);
  
  if (usersToUnbind.length === 0) {
    ElMessage.warning('选中的用户中没有已绑定设备的用户');
    return;
  }

  try {
    // 显示确认对话框
    await ElMessageBox.confirm(
      `确定要解绑选中的 ${usersToUnbind.length} 个用户的设备吗？`,
      '批量解绑确认',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
    );

    let completedCount = 0;
    const totalCount = usersToUnbind.length;

    // 创建加载提示
    const loadingInstance = ElLoading.service({
      lock: true,
      text: `正在批量解绑... (0/${totalCount})`,
      background: 'rgba(0, 0, 0, 0.7)'
    });

    try {
      await batchRequest(usersToUnbind, async (user) => {
        const unbindForm = {
          device: user.device_监测垫.deviceID,
          user: user.device_监测垫.user || user.device_监测垫.phoneNumber,
          openid: user.device_监测垫.openid
        };

        const axios = createAxiosInstance("数据服务器");
        await axios.post('/unbind/', unbindForm, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        // 更新进度
        completedCount++;
        loadingInstance.text = `正在批量解绑... (${completedCount}/${totalCount})`;
      }, 5);

      // 关闭加载提示
      loadingInstance.close();
      
      ElMessage.success(`成功解绑 ${usersToUnbind.length} 个设备`);
      await fetchData();
      selectedUsers.value = [];
      
    } catch (error) {
      // 确保加载提示被关闭
      loadingInstance.close();
      throw error;
    }
    
  } catch (error) {
    if (error !== 'cancel') {
      console.error('批量解绑失败:', error);
      ElMessage.error('批量解绑失败');
    }
  }
};
</script>

<style scoped>
/* 表格样式 */
.custom-table :deep(.el-table__body-wrapper) {
  overflow-y: auto;
}

.custom-table {
  --el-table-border-color: theme('colors.slate.200');
  --el-table-header-bg-color: theme('colors.slate.50');
  --el-table-header-text-color: theme('colors.slate.700');
  --el-table-row-hover-bg-color: theme('colors.blue.50');
}

.custom-table :deep(.el-table__row) {
  border-bottom: 1px solid theme('colors.slate.100');
}

/* 单选按钮组样式 */
.el-radio-group :deep(.el-radio-button__inner) {
  @apply px-6 py-2.5 text-sm border-slate-200;
  height: auto;
  line-height: 1.5;
}

.el-radio-group :deep(.el-radio-button:first-child .el-radio-button__inner) {
  @apply border-r-0;
}

.el-radio-group :deep(.el-radio-button:last-child .el-radio-button__inner) {
  @apply border-l-0;
}

.el-radio-group :deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  @apply bg-blue-500 border-blue-500 text-white;
  box-shadow: -1px 0 0 0 #3b82f6;
}

/* 对话框样式 */
.custom-dialog :deep(.el-dialog__header) {
  @apply px-6 py-4 border-b border-slate-200;
}

.custom-dialog :deep(.el-dialog__title) {
  @apply text-lg font-semibold text-slate-800;
}

.custom-dialog :deep(.el-dialog__body) {
  @apply p-6;
}

.custom-dialog :deep(.el-dialog__footer) {
  @apply px-6 py-4 border-t border-slate-200;
}

/* 表单样式 */
.add-user-form :deep(.el-form-item__label) {
  @apply text-slate-700 font-medium;
}

.add-user-form :deep(.el-input__wrapper) {
  @apply shadow-none border border-slate-200 hover:border-blue-400;
}

.add-user-form :deep(.el-input__wrapper.is-focus) {
  @apply ring-2 ring-blue-500 border-transparent;
}

.add-user-form :deep(.el-input-number__wrapper) {
  @apply shadow-none border border-slate-200 hover:border-blue-400;
}

/* 添加设备ID的悬停样式 */
.device-id-link {
  @apply cursor-pointer transition-colors;
}

.device-id-link:hover {
  @apply bg-opacity-75;
}
</style>
