<template>
  <div class="min-h-screen bg-slate-100 p-4 space-y-4">
    <!-- 筛选按钮区域 -->
    <div class="mx-auto flex justify-end" :class="[isNavCollapsed ? 'max-w-[120rem]' : 'max-w-[100rem]']">
      <button 
        v-if="selectedReport"
        @click="toggleFilter"
        class="flex items-center gap-2 px-4 py-2 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-200 font-medium shadow-sm border border-blue-100"
      >
        <svg 
          class="w-5 h-5 transition-transform duration-200"
          :class="{ 'rotate-180': !isFilterCollapsed }"
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
        </svg>
        <span>{{ isFilterCollapsed ? '展开筛选' : '收起筛选' }}</span>
      </button>
    </div>

    <!-- 筛选区域 - 增加投影和边框 -->
    <div 
      class="mx-auto bg-white rounded-xl shadow-md border border-slate-200 mb-4 transition-all duration-300 origin-top"
      :class="[
        isFilterCollapsed ? 'h-0 opacity-0 overflow-hidden' : 'h-auto opacity-100',
        isNavCollapsed ? 'max-w-[120rem]' : 'max-w-[100rem]'
      ]"
    >
      <div class="p-6">
        <!-- 搜索和日期选择区域 - 添加背景色区分 -->
        <div class="flex items-center gap-6 mb-8 p-4 bg-slate-50 rounded-lg">
          <!-- 搜索框 - 增强视觉效果 -->
          <div class="relative flex-1 max-w-xs">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="搜索设备ID..."
              class="w-full px-4 py-2.5 bg-white border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none shadow-sm"
              @input="handleSearch"
            />
            <!-- 搜索图标 -->
            <svg 
              v-if="!searchQuery"
              class="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-slate-400"
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <!-- 清除按钮 -->
            <button
              v-else
              @click="clearSearch"
              class="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-slate-400 hover:text-slate-600 transition-colors"
            >
              <svg fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!-- 日期选择器区域 -->
          <div v-if="currentView === 'byDate'" class="flex items-center gap-3">
            <span class="text-sm font-medium text-slate-700">日期范围:</span>
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              class="!w-80"
              @change="handleDateRangeChange"
            />
          </div>
        </div>

        <!-- 标签筛选部分 - 优化视觉层次 -->
        <div class="space-y-8">
          <div>
            <div class="text-sm font-medium text-slate-700 mb-3">临床指标:</div>
            <div class="flex flex-wrap gap-2">
              <button
                v-for="tag in reportTags.指标"
                :key="tag"
                @click="toggleTag('指标', tag)"
                :class="[
                  'px-4 py-1.5 rounded-lg text-sm font-medium transition-all duration-200',
                  selectedTags.指标.includes(tag)
                    ? 'bg-blue-100 text-blue-700 ring-1 ring-blue-300 shadow-sm'
                    : 'bg-white text-slate-600 ring-1 ring-slate-200 hover:bg-slate-50'
                ]"
              >
                {{ tag }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 报告列表区域 - 增强卡片效果 -->
    <div 
      class="mx-auto bg-white rounded-lg shadow-md border border-slate-200"
      :class="[
        selectedReport ? 'flex gap-3' : '',
        isNavCollapsed ? 'max-w-[120rem]' : 'max-w-[100rem]'
      ]"
    >
      <!-- 左侧报告列表 -->
      <div 
        :class="[
          selectedReport ? 'w-1/5 h-[calc(100vh-8rem)] overflow-y-auto border-r border-slate-200 bg-slate-50' : 'w-full'
        ]"
        ref="reportsContainer"
      >
        <div 
          v-if="currentView === 'byDate'" 
          class="p-4 space-y-3"
          :style="reports.length < 4 ? 'min-height: 100%; display: flex; flex-direction: column; justify-content: center;' : ''"
        >
          <div
            v-for="report in reports"
            :key="report.info.user_id"
            :ref="el => { if (report.info.user_id === selectedReport?.info.user_id) selectedReportRef = el }"
            class="bg-white rounded-lg transition-all duration-200 cursor-pointer"
            :class="[
              selectedReport?.info.user_id === report.info.user_id 
                ? 'border-2 border-blue-500 shadow-md ring-1 ring-blue-200' 
                : 'border border-slate-200 hover:border-blue-300 hover:shadow-sm'
            ]"
            @click="viewReport(report)"
          >
            <!-- 当选中报告时，显示简化版卡片 -->
            <div class="p-4" v-if="selectedReport">
              <div class="space-y-2">
                <div class="flex justify-between items-center">
                  <h3 class="text-base font-medium text-slate-800">设备 {{ report.info.device_id }}</h3>
                  <span class="text-sm text-slate-400">{{ report.info.date }}</span>
                </div>
                <div class="flex items-center gap-1.5">
                  <span class="text-sm text-slate-500">AHI</span>
                  <span 
                    class="text-base font-medium"
                    :class="{
                      'text-green-600': report.info.avg_ahi < 5,
                      'text-yellow-600': report.info.avg_ahi >= 5 && report.info.avg_ahi < 15,
                      'text-red-600': report.info.avg_ahi >= 15
                    }"
                  >
                    {{ report.info.avg_ahi?.toFixed(1) || '-' }}
                  </span>
                </div>
              </div>
            </div>
            
            <!-- 未选中报告时，显示完整卡片 -->
            <div class="p-4" v-else>
              <!-- 原有的完整卡片内容 -->
              <div class="flex items-center gap-6">
                <!-- 设备和机构信息 -->
                <div class="flex items-center gap-3 min-w-[300px]">
                  <h3 class="text-base font-medium text-slate-900">设备 {{ report.info.device_id }}</h3>
                  <div class="text-sm text-slate-500 flex items-center gap-2">
                    <span>{{ report.info.org }}</span>
                    <span class="inline-block w-1 h-1 rounded-full bg-slate-300"></span>
                    <span>{{ report.info.date }}</span>
                  </div>
                </div>

                <!-- 关键指标 -->
                <div class="flex items-center gap-6 flex-1">
                  <div class="flex items-center gap-1.5">
                    <span class="text-sm text-slate-500">AHI</span>
                    <span class="text-base font-medium text-slate-900">{{ report.info.avg_ahi?.toFixed(1) || '-' }}</span>
                  </div>
                  <div class="flex items-center gap-1.5">
                    <span class="text-sm text-slate-500">心率</span>
                    <span class="text-base font-medium text-slate-900">{{ report.info.avg_sleep_heart_rate }}</span>
                  </div>
                  <div class="flex items-center gap-1.5">
                    <span class="text-sm text-slate-500">睡眠时长</span>
                    <span class="text-base font-medium text-slate-900">{{ formatMinutesToHours(report.info.total_sleep_duration) }}</span>
                  </div>
                  <div class="flex items-center gap-1.5">
                    <span class="text-sm text-slate-500">效率</span>
                    <span class="text-base font-medium text-slate-900">{{ (report.info.core_sleep_efficiency * 100).toFixed(1) }}%</span>
                  </div>
                </div>

                <!-- 操作按钮 -->
                <button
                  class="flex items-center gap-1 px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded-md transition-colors duration-200"
                  @click="viewReport(report)"
                  title="查看详情"
                >
                  查看详情
                  <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                  </svg>
                </button>
              </div>

              <!-- 指标标签 -->
              <div class="mt-3 flex flex-wrap gap-1.5">
                <span
                  v-for="(value, key) in report.指标"
                  :key="key"
                  class="px-2 py-0.5 text-xs font-medium bg-blue-50 text-blue-700 rounded-full"
                >
                  {{ key }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 右侧报告详情 - 优化边框和阴影 -->
      <div v-if="selectedReport" class="w-4/5 h-[calc(100vh-8rem)] flex flex-col bg-slate-50 rounded-r-lg">
        <div class="bg-white rounded-lg shadow-md h-full flex flex-col">
          <!-- 报告详情头部 - 固定在顶部 -->
          <div class="sticky top-0 bg-white p-4 border-b border-gray-200 flex justify-between items-center z-10">
            <h2 class="text-lg font-semibold flex items-center">
              <svg class="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              睡眠报告详情 - 设备：{{ selectedReport.info.device_id }}
            </h2>
            <div class="flex items-center space-x-3">
              <button
                @click="closeReport"
                class="flex items-center px-4 py-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-all duration-200 space-x-2"
              >
                <span>关闭</span>
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <button
                class="flex items-center px-3 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
                @click="handleEditInfo"
              >
                <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                编辑信息
              </button>
              <button
                class="flex items-center px-3 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
                @click="printReport"
              >
                <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                </svg>
                打印
              </button>
            </div>
          </div>
          
          <!-- 报告详情内容 - 可滚动区域 -->
          <div class="flex-1 overflow-y-auto">
            <div class="p-3">
              <OrgReportDetail
                v-if="selectedReport"
                :key="selectedReport.info.device_id"
                v-model:device="selectedReport.info.device_id"
                :selections="selectedReport.row_data"
                :date="selectedReport.info.date"
                :stage="1"
                @date-change="handleDateChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页部分 - 增加背景色和间距 -->
    <div 
      class="mx-auto mt-6 flex justify-center p-4 bg-white rounded-lg shadow-sm border border-slate-200"
      :class="[isNavCollapsed ? 'max-w-[120rem]' : 'max-w-[100rem]']"
    >
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        class="flex items-center justify-center"
      />
    </div>
  </div>
  <PatientInfoEdit
    v-model="showEditModal"
    :patient-info="patientForm"
    :customFields="customFields"
    @save="handleSavePatientInfo"
  />
</template>

<script setup>
import { ref, onMounted, watch, reactive, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import {createAxiosInstance, wdb_get_history_new,request_data, wdb_get_new} from "@/plugins/axios.js";
import OrgReportDetail from "@/components/OrgReportDetail.vue";
import PatientInfoEdit from "@/components/PatientinfoEdit.vue";
const route = useRoute()
const router = useRouter()
const userinfo = ref(null)
// 筛选条件
const currentView = ref('byDate')
const selectedDevice = ref('')
const dateRange = ref(null)
const selectedStatus = ref('all')
const serverType = ref('计算服务器1')
const currentReportDate = ref(null) // 新增：用于跟踪当前选中的报告日期
const loading = ref(false)
const searchQuery = ref('')
const searchDebounceTimer = ref(null)


// 分页
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(100)

// 示例报告数据
const reports = ref([])

// 定义标签数据 根据客户需求定制
const reportTags = reactive({
  指标: [
    "轻度OSA", 
    "中度OSA", 
    "重度OSA",
    "睡眠潜伏期长", 
    "睡眠时间不足", 
    "觉醒时间长", 
    "浅睡占比高", 
    "深睡占比低", 
    "REM占比低"
  ]
})

// 选中的标签 - 确保使用 reactive 而不是 ref
const selectedTags = reactive({
  指标: []
})

// 切换标签选中状态并发送请求
const toggleTag = async (category, tag) => {
  const index = selectedTags[category].indexOf(tag)
  if (index === -1) {
    // 添加标签
    selectedTags[category].push(tag)
  } else {
    // 移除标签
    selectedTags[category].splice(index, 1)
  }
  
  try {
    // 重置分页
    currentPage.value = 1
    await fetchReports()
  } catch (error) {
    ElMessage.error('获取数据失败')
    console.error('获取数据失败:', error)
  }
}
// 修改 fetchReports 方法
const fetchReports = async () => {
  loading.value = true
  try {
    // 构建过滤条件
    let filterParts = []
    
    // 添加标签过滤
    if (selectedTags.指标.length > 0) {
      filterParts.push(selectedTags.指标.map(tag => 'all_tags.' + tag).join(' AND '))
    }
    
    // 添加搜索过滤
    if (searchQuery.value.trim()) {
      filterParts.push(`search*${searchQuery.value.trim()}`)
    }
    
    // 添加日期范围过滤
    if (dateRange.value && dateRange.value[0] && dateRange.value[1]) {
      // 计算日期范围天数
      const start = new Date(dateRange.value[0])
      const end = new Date(dateRange.value[1])
      const diffTime = Math.abs(end - start)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
      filterParts.push(`daterange=${diffDays}`)
    }
    
    // 组合所有过滤条件
    const filterStr = filterParts.join(' AND ')
    
    // 构建请求 URL
    let url = `/report/?filter=${encodeURIComponent(filterStr)}&page=${currentPage.value}&pageSize=${pageSize.value}`
    
    // 使用结束日期作为 date 参数
    if (dateRange.value && dateRange.value[1]) {
      url += `&date=${dateRange.value[1]}`  // 改为使用结束日期
    }
    
    const response = await request_data(url, serverType.value)
    
    reports.value = response.nodes
    total.value = response.count
    
    await new Promise(resolve => setTimeout(resolve, 500))
  } catch (error) {
    ElMessage.error('获取报告列表失败')
    console.error('获取报告失败:', error)
  } finally {
    loading.value = false
  }
}

// 修改日期范围变化处理方法
const handleDateRangeChange = () => {
  currentPage.value = 1 // 重置页码
  fetchReports()
}

// 添加分页处理方法
const handleSizeChange = (val) => {
  pageSize.value = val
  fetchReports()
}

const handleCurrentChange = (val) => {
  currentPage.value = val
  fetchReports()
}

// 页面加载时获取数据
onMounted(() => {
  fetchReports()
})

// 处理路由参数
const initFromRoute = () => {
  if (route.query.deviceId) {
    currentView.value = 'byDevice'
    selectedDevice.value = route.query.deviceId
    // 可以设置一个默认的日期范围，比如最近30天
    const end = new Date()
    const start = new Date()
    start.setDate(start.getDate() - 30)
    dateRange.value = [start, end]
  }
}

// 监听路由变化
watch(
  () => route.query,
  () => {
    initFromRoute()
    fetchReports()
  },
  { immediate: true }
)

// 修改状态选择逻辑
const toggleStatus = (status) => {
  selectedStatus.value = selectedStatus.value === status ? '' : status
  fetchReports()
}

const formatUnixTimestamp = (timestamp) => {
  if (!timestamp) return '-'
  const date = new Date(timestamp * 1000)
  return date.toLocaleString('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

// 隐藏电话号码中间四位
const hidePhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

// 将分钟转换为小时和分钟的格式
const formatMinutesToHours = (minutes) => {
  if (!minutes) return '-'
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  if (hours === 0) {
    return `${remainingMinutes}分钟`
  }
  return remainingMinutes > 0 ? `${hours}小时${remainingMinutes}分钟` : `${hours}小时`
}

// 添加 toggleDetails 方法
const toggleDetails = (report) => {
  if (!report.info.showDetails) {
    report.info.showDetails = false;
  }
  report.info.showDetails = !report.info.showDetails;
}

// 监标签变化
watch(
  () => [...selectedTags.指标],
  () => {
    console.log('Selected tags changed:', selectedTags.指标)
  }
)

// 添加新的响应式变量
const selectedReport = ref(null)

// 添加折叠状态
const isFilterCollapsed = ref(false)

// 添加切换筛选模块的方法
const toggleFilter = () => {
  isFilterCollapsed.value = !isFilterCollapsed.value
}
// 添加新的响应式变量
const showEditModal = ref(false)
const patientForm = ref({
  name: '',
  gender: '',
  birthDate: '',
  age: '',
  ethnicity: '',
  height: null,
  weight: null,
  bmi: '',
})
const customFields = ref([])

const handleSavePatientInfo = async (data) => {
  try {
    // 处理保存逻辑
     // 合并基本信息和自定义字段
     console.log(data)
     const formData = {
      deviceId: selectedReport.value.info.device_id, // 添加设备ID
      basicInfo: {
        name: data.basicInfo.name,
        gender: data.basicInfo.gender,
        ethnicity: data.basicInfo.ethnicity,
        birthDate: data.basicInfo.birthDate,
        age: data.basicInfo.age,
        height: data.basicInfo.height,
        weight: data.basicInfo.weight,
        bmi: data.basicInfo.bmi
      },
      customFields: data.customFields
    }
    let formattedDate;
    if (typeof currentReportDate.value === 'string') {
      formattedDate = currentReportDate.value;
    } else if (currentReportDate.value instanceof Date) {
      formattedDate = currentReportDate.value.toISOString().split('T')[0];
    } else {
      const date = new Date(currentReportDate.value);
      formattedDate = date.toISOString().split('T')[0];
    }
    const axiosInstance = createAxiosInstance('计算服务器1')
    const response = await axiosInstance.post(`report_info_edit/${selectedReport.value.info.device_id}/${formattedDate}/`, formData,{
        headers: {
          'Content-Type': 'application/json'
        }})
    
    if (response.data.status === 'success') {
      ElMessage.success('保存成功')
      // 更新本地数据
      if (selectedReport.value && selectedReport.value.row_data) {
        selectedReport.value.row_data.userinfo = data
      }
    } else {
      ElMessage.error('保存失败')
    }
  } catch (error) {
    console.error('保存者信息失败:', error)
    ElMessage.error('保存失败')
  }
}
const viewReport = async (report) => {
  try {
    // 折叠筛选区域
    isFilterCollapsed.value = true
    currentReportDate.value = report.info.date; // 初始化当前报告日期

    const axiosInstance = createAxiosInstance('计算服务器1')

    const response = await axiosInstance.get(`report_info/${report.info.device_id}/${report.info.date}/`)
    
    if (response.data.status === 'success' && response.data.data) {
      const { basicInfo, customFields } = response.data.data
      report.row_data = {
        userinfo: {
          username: basicInfo.name,
          sex: basicInfo.gender,
          ethnicity: basicInfo.ethnicity,
          birthday: basicInfo.birthDate,
          age: basicInfo.age,
          height: basicInfo.height,
          weight: basicInfo.weight,
          bmi: basicInfo.bmi,
          customData: customFields
        }
      }
      selectedReport.value = { ...report }
      
      // 等待 DOM 更新后执行滚动
      await nextTick(() => {
        if (selectedReportRef.value && reportsContainer.value) {
          // 计算需要滚动的位置
          const container = reportsContainer.value
          const element = selectedReportRef.value
          const containerHeight = container.clientHeight
          const elementHeight = element.clientHeight
          const elementTop = element.offsetTop
          const containerScrollTop = container.scrollTop
          
          // 计算元素应该滚动到的位置（居中）
          const targetScrollTop = elementTop - (containerHeight / 2) + (elementHeight / 2)
          
          // 平滑滚动到目标位置
          container.scrollTo({
            top: targetScrollTop,
            behavior: 'smooth'
          })
        }
      })
    }else{
    const response_userinfo = await wdb_get_new('数据服务器','user',report.info.phonenumnber)
    if (Object.keys(response_userinfo).length > 0 && response_userinfo.device_监测垫) {
      const basicInfo = response_userinfo.device_监测垫
      report.row_data = {
        userinfo: {
          username:   response_userinfo.username,
          sex: basicInfo.sex,
          ethnicity: basicInfo.ethnicity,
          birthday: basicInfo.birthday,
          age: basicInfo.age,
          height: basicInfo.height,
          weight: basicInfo.weight,
          bmi: basicInfo.bmi,
          customData: customFields
        }
      }
      selectedReport.value = { ...report }

    }else{
      report.row_data = {
        userinfo: {
          username:   '',
          sex: '',
          ethnicity: '',
          birthday: '',
          age: '',
          height: '',
          weight: '',
          bmi: '',
          customData: []
        }
      }
      selectedReport.value = { ...report }




    }

      }
    // 触发事件通知父组件折叠导航栏
    emit('view-report')
  } catch (error) {
    console.error('获取报告详情失败:', error)
    ElMessage.error('获取报告详情失败')
  }
}

// 修改关闭报告方法
const closeReport = () => {
  selectedReport.value = null
  // 展开筛选区域
  isFilterCollapsed.value = false
}

// 定义 emit
const emit = defineEmits(['view-report'])

// 添加打印方法
const printReport = () => {
  const url = router.resolve({
    name: 'report-detail',
    query: {
      device: selectedReport.value.info.device_id,
      date: selectedReport.value.info.date,
      selections: JSON.stringify(selectedReport.value.row_data),
            print: 'true' // 添加打印标记

    }
  }).href;
  
  window.open(url, '_blank');
}

async function handleEditInfo() {
  // try {
    const axiosInstance = createAxiosInstance('计算服务器1');
    let formattedDate;
    if (typeof currentReportDate.value === 'string') {
      formattedDate = currentReportDate.value;
    } else if (currentReportDate.value instanceof Date) {
      formattedDate = currentReportDate.value.toISOString().split('T')[0];
    } else {
      const date = new Date(currentReportDate.value);
      formattedDate = date.toISOString().split('T')[0];
    }
    const response = await axiosInstance.get(`report_info/${selectedReport.value.info.device_id}/${formattedDate}/`);
    
    if (response.data.status === 'success' && response.data.data) {
      const { basicInfo, customFields: customFieldsData } = response.data.data;
      patientForm.value = {
        name: basicInfo.name || '',
        gender: basicInfo.gender || '',
        ethnicity: basicInfo.ethnicity || '',
        birthDate: formatDateValue(basicInfo.birthDate), // 使用格式化函数处理日期
        age: basicInfo.age || '',
        height: basicInfo.height || null,
        weight: basicInfo.weight || null,
        bmi: basicInfo.bmi || ''
      };
      
      customFields.value = Object.entries(customFieldsData || {}).map(([label, value]) => ({
        label,
        value
      }));

    } else {

      const bedUserInfo = selectedReport.value.row_data.userinfo;
      if (bedUserInfo) {
        patientForm.value = {
          name: bedUserInfo.username || '',
          gender: bedUserInfo.sex || '',
          ethnicity: bedUserInfo.ethnicity || '',
          birthDate: formatDateValue(bedUserInfo.birthday), // 使用格式化函数处理日期
          age: bedUserInfo.age || '',
          height: bedUserInfo.height || null,
          weight: bedUserInfo.weight || null,
          bmi: bedUserInfo.bmi || ''
        };
      } else {
        patientForm.value = {
          name: '',
          gender: '',
          ethnicity: '',
          birthDate: '',
          age: '',
          height: null,
          weight: null,
          bmi: ''
        };
      }
      customFields.value = [];
    }
    
    showEditModal.value = true;
    
  } 
  // catch (error) {
  //   console.error('获取报告信息失败:', error);
  //   ElMessage.error('获取报告信息失败');
  // }
// }


// 添加导航栏状态
const isNavCollapsed = ref(false)

// 从父组件接收导航栏状态
const props = defineProps({
  navCollapsed: {
    type: Boolean,
    default: false
  }
})

// 监听父组件传递的导航栏状态
watch(() => props.navCollapsed, (newVal) => {
  isNavCollapsed.value = newVal
})
// 计算 BMI
const calculateBMI = () => {
  if (patientForm.value.height && patientForm.value.weight) {
    const heightInMeters = patientForm.value.height / 100
    patientForm.value.bmi = (patientForm.value.weight / (heightInMeters * heightInMeters)).toFixed(1)
  }
}
// 监听 height 和 weight 的变化
watch([() => patientForm.value.height, () => patientForm.value.weight], ([newHeight, newWeight]) => {
  calculateBMI(newHeight, newWeight);
});
// 清除搜索内容
const clearSearch = () => {
  searchQuery.value = ''
  currentPage.value = 1 // 重置页码
  fetchReports()
}

const reportsContainer = ref(null)
const selectedReportRef = ref(null)

// 监听报告数量变化，如果报告很少则自动居中显示
watch(() => reports.value.length, (newLength) => {
  if (newLength < 4) {
    nextTick(() => {
      if (reportsContainer.value) {
        reportsContainer.value.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    })
  }
})
// 添加一个新的方法来处理日期更新
const handleDateChange = async (newDate) => {
  
  let formattedLastUpdate;
    if (newDate instanceof Date) {
      formattedLastUpdate = newDate.toISOString().split('T')[0];
    } else {
      const [datePart] = newDate.split(' ');
      formattedLastUpdate = datePart;
    }
  currentReportDate.value = formattedLastUpdate;
  // selectedDate.value = newDate
  // 重置 selectedReport
  // selectedReport.value = null
  await   fetchReports()
}

// 添加日期格式化工具函数
const formatDateValue = (dateValue) => {
  if (!dateValue) return '';
  
  try {
    // 处理时间戳（假设是秒级时间戳）
    if (typeof dateValue === 'number') {
      const date = new Date(dateValue * 1000);
      return date.toISOString().split('T')[0];
    }
    
    // 处理 YYYY-MM-DD 格式的字符串
    if (typeof dateValue === 'string' && dateValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return dateValue;
    }
    
    // 处理其他日期字符串
    const date = new Date(dateValue);
    if (!isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    }
    
    return '';
  } catch (error) {
    console.error('日期格式化错误:', error);
    return '';
  }
};

// 处理搜索
const handleSearch = () => {
  // 清除之前的定时器
  if (searchDebounceTimer.value) {
    clearTimeout(searchDebounceTimer.value)
  }
  
  // 设置新的定时器，300ms 后执行搜索
  searchDebounceTimer.value = setTimeout(() => {
    currentPage.value = 1 // 重置页码
    fetchReports()
  }, 300)
}
</script>

<style scoped>
/* 优化分页组件样式 */
:deep(.el-pagination) {
  justify-content: center;
  white-space: nowrap;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled):hover) {
  color: #3b82f6;
}

/* 优化卡片悬停效果 */
.hover\:scale-\[1\.01\]:hover {
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}

/* 添加平滑滚动 */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f8fafc;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 2px;
}

/* 添加平滑过渡效果 */
.transition-all {
  transition: all 0.2s ease-in-out;
}

/* 当报告数量少时的容器样式 */
.reports-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}
</style>