import { getColor, seconds2HHmm, formatDateString, ledColor } from '@/plugins/utils/utils';

import { locales } from '@/plugins/utils/locales';
const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

let hrv_chart    = null;

import {
    chart_dates, // 图表横轴的日期与报告数据之间的映射关系
    getInterval
  } from './variables.js';

export function getSymbol(value, params, data) { //单点数据需要显示symbol，能连成线则不显示
    var left = params.dataIndex -1;
    var right = params.dataIndex +1;
    var symbol = 'none';
    if (left>=0 && right<data.length) { //中间单点
        if (data[left][1]==null && data[right][1]==null) {
            symbol = 'circle';
        }
    } else if (right<data.length) { //最左边缘
        if (data[right][1]==null) symbol = 'circle';
    } else if (left>=0) { //最右边缘
        if (data[left][1]==null) symbol = 'circle';
    }
    return symbol;
}

export function setHRVChartOption(reports, hrv_chart, chart_dates) {
    if (!hrv_chart) {
        return;
    }
    var dataHRV = [];
    var dataHRVavg = [];
    var dataUpper = [];
    var dataLower = [];
    var dataXAxis = [];

    var weekmode = false;
    if (Object.keys(reports).length > 0) {
        var total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total <= 60) {
            let totalHole = 0;  
            for (var key in chart_dates) {
                try {
                    const [year,month, day] = key.split('/');
                    let abbrKey;
                    if (day === undefined) {
                        abbrKey = year + '-' + month;
                    } else {
                        abbrKey = month + '-' + day;
                    }
                    let hrv = null, avg = null, upper = null, lower = null;
                    // 检查 reports[key] 是否存在
                    if (reports[key]) {
                        // 使用可选链操作符和空值合并操作符来安全地获取值
                        hrv = reports[key].hrv_rmssd_mean != null ? Math.round(reports[key].hrv_rmssd_mean) : null;
                        avg = reports[key].weekly_avg_hrv_rmssd != null ? Math.round(reports[key].weekly_avg_hrv_rmssd) : null;
                        upper = reports[key].hrv_limit_max != null ? Math.round(reports[key].hrv_limit_max) : null;
                        lower = reports[key].hrv_limit_min != null ? Math.round(reports[key].hrv_limit_min) : null;
                    }
            
                    // 验证函数
                    const valid = function(value) { 
                        return !isNaN(value) && value !== null 
                    };
            
                    // 设置默认颜色
                    let hrvColor = ledColor[0];
                    
                    // 只在有效值时进行比较
                    if (valid(hrv) && valid(upper) && valid(lower)) {
                        if (hrv > upper || hrv < lower) {
                            hrvColor = ledColor[1];
                        }
                    }
            
                    // 添加数据点
                    dataHRV.push({ 
                        name: abbrKey, 
                        value: hrv, 
                        itemStyle: { color: hrvColor } 
                    });
                    
                    dataHRVavg.push({ 
                        name: abbrKey, 
                        value: avg 
                    });
                    
                    dataUpper.push([
                        abbrKey, 
                        valid(upper) && valid(lower) ? (upper - lower) : null
                    ]);
                    
                    dataLower.push([
                        abbrKey, 
                        valid(upper) && valid(lower) ? lower : null
                    ]);
            
                    if (!valid(upper) || !valid(lower)) {
                        totalHole++;
                    }
                    
                    dataXAxis.push(abbrKey);
            
                } catch (error) {
                    console.error(`Error processing data for date ${key}:`, error);
                    // 可以选择添加空值来保持数据点数量一致
                    dataHRV.push({ name: abbrKey, value: null, itemStyle: { color: ledColor[0] } });
                    dataHRVavg.push({ name: abbrKey, value: null });
                    dataUpper.push([abbrKey, null]);
                    dataLower.push([abbrKey, null]);
                    dataXAxis.push(abbrKey);
                    totalHole++;
                }
            }
            
            if (totalHole) {
                let holeStart = 0;
                // 如果空洞不超过4天，不要断开空洞的部分，平滑过渡
                for (let i = 0; i < dataUpper.length; i++) {
                    if (dataUpper[i][1]) {
                        if (holeStart && (i - holeStart) <= 4) {
                            dataUpper.splice(holeStart, i - holeStart);
                            dataLower.splice(holeStart, i - holeStart);
                            i = holeStart;
                        }
                        holeStart = 0;
                    } else if (!holeStart) {
                        holeStart = i;
                    }
                }
            }
        } else {
            weekmode = true;
            let [hrv_avg, avg_avg, upper_avg, lower_avg, count, countBase] = [0, 0, 0, 0, 0, 0];
            let valid = false; // 跳过开头的“半周”
            let weekend = '';
            for (let key in chart_dates) {
                let [year, month, day] = key.split('/');
                let d = new Date();
                d.setHours(0, 0, 0, 0);
                d.setFullYear(year, month - 1, day);
                let weekday = d.getDay();

                let hrv = null, avg = null, upper = null, lower = null;
            
                // 检查 reports[key] 是否存在
                if (reports[key]) {
                    // 使用可选链操作符和空值合并操作符来安全地获取值
                    hrv = reports[key].hrv_rmssd_mean != null ? Math.round(reports[key].hrv_rmssd_mean) : null;
                    avg = reports[key].weekly_avg_hrv_rmssd != null ? Math.round(reports[key].weekly_avg_hrv_rmssd) : null;
                    upper = reports[key].hrv_limit_max != null ? Math.round(reports[key].hrv_limit_max) : null;
                    lower = reports[key].hrv_limit_min != null ? Math.round(reports[key].hrv_limit_min) : null;
                }
        
                // 
                if (hrv != null && avg != null && valid) {
                    hrv_avg += hrv;
                    avg_avg += avg;
                    count++;
                }
                if (upper != null && lower != null && valid) {
                    upper_avg += upper;
                    lower_avg += lower;
                    countBase++;
                }
                if (weekday === 6) { //每周最后一天
                    weekend = month + '-' + day;
                }
                if (weekday === 0) { //每周第一天
                    if (valid) {
                        hrv_avg = count ? Math.round(hrv_avg / count) : null;
                        avg_avg = count ? Math.round(avg_avg / count) : null;
                        upper_avg = countBase ? Math.round(upper_avg / countBase) : null;
                        lower_avg = countBase ? Math.round(lower_avg / countBase) : null;

                        let hrvColor = ledColor[0];
                        if (hrv_avg > upper_avg || hrv_avg < lower_avg) {
                            hrvColor = ledColor[1];
                        }

                        key = month + '-' + day + L.to + weekend;
                        dataHRV.push({ name: key, value: hrv_avg, itemStyle: { color: hrvColor, } });
                        dataHRVavg.push({ name: key, value: avg_avg });
                        dataUpper.push([key, upper_avg ? (upper_avg - lower_avg) : null]);
                        dataLower.push([key, lower_avg]);
                        dataXAxis.push(key);
                        [hrv_avg, avg_avg, upper_avg, lower_avg, count, countBase] = [0, 0, 0, 0, 0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
        }
    }
    dataHRV.reverse();
    dataHRVavg.reverse();
    dataUpper.reverse();
    dataLower.reverse();
    dataXAxis.reverse();
    var option = {
        backgroundColor: 'transparent',
        animationDuration: 500,
        /*title: {
            text: '睡眠心率 (次/分)',
            padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        },*/
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'seriesAsc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            axisPointer: {
                type: 'line',
                label: { 
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            //valueFormatter: (value) => value ? value + '毫秒' : '-',
            formatter: (params)=>{
                if (params.length != 3)
                    return null;
                return params[0].name + '\n'
                    + params[0].marker + params[0].seriesName + '    ' + (params[0].value ? Math.round(params[0].value) + L.ms : L.nodata_abbr) + '\n'
                    + params[1].marker + params[1].seriesName + '    ' + (params[1].value[1] ? (params[1].value[1] + ' - ' + (params[1].value[1] + params[2].value[1]) + L.ms) : L.nodata_abbr);
            }
        },
        /*legend: {
            data: [{ name: 'HRV RMSSD' }, { name: L.baseline, icon: 'roundRect', itemStyle: { opacity: 0.8 } }],
            orient: 'horizontal',
            bottom: 1,
            selectedMode: false,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
            itemGap: 6,
            textStyle: {
                fontSize: 12,
                padding: [0, 0, 0, -2],
            },
        },*/
        grid: {
            top: '6%',
            left: '3%',
            right: '3%',
            bottom: '0%', //weekmode ? '18%' : '12%',
            containLabel: true,
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisTick: { alignWithLabel: true },
            min: 'dataMin',
            max: 'dataMax',
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : 1,
                formatter: (value)=>{
                    if (weekmode) {
                        let [weekstart, weekend] = value.split(L.to);
                        let [startMonth, startDay] = weekstart.split('-');
                        let [endMonth, endDay] = weekend.split('-');
                        if (startMonth!=endMonth || startDay==1) { // 月初
                            return monthNameArray[parseInt(endMonth) - 1];
                        } else { return '' }
                    } else { return value; }
                }
            },
            data: dataXAxis,
        }],
        yAxis: [{
            type: 'value',
            minInterval: 5,
            min: function (value) { return Math.floor((value.min - 5)/10)*10 },
            max: function (value) { return Math.ceil((value.max + 5)/10)*10 },
            axisLabel: {
                show: true,
            }
        }],
        series: [{
            name: 'HRV RMSSD',
            type: 'line',
            lineStyle: { color: getColor('linegray1') },
            itemStyle: { color: getColor('linegray1') },
            symbol: 'circle',
            symbolSize: 5,
            smooth: true,
            emphasis: { disabled: true },
            data: dataHRV,
        },{
            name: L.baseline,
            type: 'line',
            stack: 'band',
            silent: true,
            emphasis: { disabled: true },
            itemStyle: { color: '#8DD783', opacity: 0.5 },
            lineStyle: { color: '#8DD783', type: [4], opacity: 0.5, },
            symbol: (value, params)=>{ return getSymbol(value, params, dataLower); },
            smooth: true,
            data: dataLower,
        },{
            name: '基线上限',
            type: 'line',
            stack: 'band',
            silent: true,
            emphasis: { disabled: true },
            itemStyle: { color: '#8DD783', opacity: 0.5 },
            lineStyle: { color: '#8DD783', type: [4], opacity: 0.5, },
            symbol: (value, params)=>{ return getSymbol(value, params, dataUpper); },
            smooth: true,
            //areaStyle: { color: getColor('tag'), opacity: 0.8 },
            areaStyle: { color: '#8DD783', opacity: 0.15 },
            data: dataUpper,
        }]
    };
    hrv_chart.setOption(option);
}
